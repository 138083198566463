// This is the project stylesheet
@import "./reset";
@import "./variables";
@import "./landingPage";
@import "./aboutUs";
@import "./valuesBanner";
@import "./heroBlock";
@import "./navbar";
@import "./residences1";
@import "./testimonials";
@import "./mobileBanner";
@import "./aboutUs";
@import "./commercialBlock";
@import "./blogBlock";
@import "./contactBlock";
@import "./accolades";
@import "./footer";
@import "./socialHandle";
@import "./transitionImage";
@import "./keyframes";
@import "./test";
@import "./customQuiz";
@import "./quizResults";
@import "./shortForm";
// @import "~bootstrap/scss/bootstrap";
button{
    text-decoration: none !important;
&:focus {
    outline:0 !important;
    border: 1px solid $grey-2;
    
}
&:active{
    border: 1px solid $grey-2;
}
&:hover{
    cursor: pointer;
}
}

.request-info-btn{
    text-decoration: none !important;
}
