

.corso-building-img{
    width: 100%;
    height: 90vh;
   object-fit: cover;
   }
   
.request-info-btn{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px 0px;
    gap: 10px;
    border:none;

    width: 281px;
    height: 56px;
    background: #D88366;
    border-radius: 8px;
    flex: none;
    order: 2;
    flex-grow: 0;
    text-decoration: none !important;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color:white;
    &:hover{
        color:white;
    }
    :link{
        text-decoration: none !important;
    }
    
}
a:link{
    text-decoration: none;
}
@media screen and (max-width:760px){
    .corso-building-img{
        width: 100%;
        height: auto;
        min-height: 300px;
        max-height: 500px;
       object-fit: cover;
       }
       
}