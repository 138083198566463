.contact-column {
    background-color: $white;
    padding: 50px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width:100%;
  }
  .contact-div {
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width:100%;
    height: auto;
    min-width: 170px;
    max-width: 500;
  }
  .contact-info{
    margin: auto;
    width:100%;
    overflow: hidden; 

  }
  .contact-blurb {
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    min-width:150px;
    overflow: hidden; 
  }
  .contact-us {
    font-family: "Proxima Nova";
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    color: $terracotta;
    text-transform: uppercase;
    margin-bottom: 5px;
    letter-spacing: 1.6px;
  }
  .schedule-a-call {
    font-family: "Playfair Display";
    font-size: 34px;
    font-weight: 400;
    line-height: normal;
    color: $black;
  }
  .contact-description {
    width:100%;
    font-family: "Proxima Nova";
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    color: $black;
    text-transform: capitalize;
  }
 .form-input{
    display: flex;
    flex-direction: column;
    padding: 0px;
    gap: 5px;
 }
 .contact-phone, .contact-email{
  width:48%;
}
 .contact-form{
    display: flex;
    flex-direction: column;
    padding: 25px;
    gap: 25px;
    width:100%;
    max-width: 600px;
    min-width:220px;
    height: auto;
    margin:auto;
    background: #EEECE6;
    border-radius: 16px;
    align-self: stretch;
    label{
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
    }
 }
 
 .contact-column{
  input[type=text],input[type=tel],input[type=date], textarea {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding:18px;
    margin-bottom: 15px;
    background: #FFFFFF;
    border: none;
    border-radius: 8px;
    flex: none;
    order: 1;
    
    align-self: stretch;
    flex-grow: 0;
    &:focus{
      outline:$grey-1;
    }
    &:active{
      outline:$grey-2;
    }
  }
 }
 

  // input:-webkit-autofill,
  // input:-webkit-autofill:hover, 
  // input:-webkit-autofill:focus, 
  // input:-webkit-autofill:active{
  //     -webkit-box-shadow: 0 0 0 30px rgb(255, 255, 255) inset !important;

  // }


  .submit-contact-btn{
    padding: 16px 54px;
    gap:10px;
    width: 100%;
    max-width: 500px;
    height:auto;
    margin:20px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #D88366;
    border-radius: 8px;
    flex: none;
    align-self: stretch;
    border: none;
    
  }
  .submit-contact-btn-txt{
    padding: 0;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: #FFFFFF;
    flex: none;
 
 
    flex-grow: 0;
  }
 
 
 #contact-date::placeholder, input::placeholder, textarea::placeholder{
  color:$grey-1;
 }
 
 .success-msg-header{
  font-family: 'Playfair Display';
  font-size: 34px;
  margin-bottom: 25px;
 }
 .success-msg-header-div{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 10px;
 }
 .success-msg-text{
  font-family: 'Proxima Nova';
  font-size: 14px;
  margin-bottom: 25px;
  &:last-of-type{
    margin-bottom: 200px;
  }
 }
 .date-picker{
  width: 100%;
  &:hover{
    cursor: pointer;
  }
 }
 
 .calendar-icon{
  position: absolute;
  top:36px;
  right:20px;
 }
 .date-input, .referred-by-div2{
  position: relative;
 }
 .referred-by-div2{
  display: flex;
  flex-direction: column;
  gap: 5px;
 }
 .brochure-checkbox{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  input{
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }
  label{
    position: relative;
    cursor: pointer;
    &::before{
      content:'';
    // -webkit-appearance: none;
    background-color: transparent;
    border: 1.8px solid $grey-1;
    border-radius: 3px;
    padding: 7px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 10px;
    }
  }
 }
 .brochure-checkbox input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 4px;
  left: 7.5px;
  width: 4.5px;
  height: 9px;
  border: solid $grey-1;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.label-div{
  display: flex;
  flex-direction: column;
  margin: 0px !important;

}

.phone-email-div-contact{
  display: flex;
  flex-direction: row;
  gap:15px;
  flex-wrap: wrap;
  width:100%;
 
}

.react-select2__control,.react-select2-referred__control{
  border:none !important;
  border-radius: 8px;
  height: 55px;
  overflow-x: scroll;
  ::-webkit-scrollbar{
    display: none;
  }
  .react-select2__placeholder{
    color:$grey-1 !important; 
    padding-left: 24px;
  }
  .react-select2-referred__placeholder{
    color:$grey-1 !important; 
    padding-left: 0px;
  }
  .react-select2__indicator-separator,.react-select2-referred__indicator-separator,.react-select2__indicators,.react-select2-referred__indicators{
    display: none;
  }

}
.react-select2__multi-value:first-of-type{
  margin-left: 25px;

}
.react-select2__control--is-focused,.react-select2-referred__control--is-focused, .react-select2__control--menu-is-open,.react-select2-referred__control--menu-is-open{
    border: none;
    box-shadow: none !important;
  }
  .date-picker-div, .time-picker-div{
    display:flex;
    flex-direction: column;
    input[type=text],input[type=tel],input[type=email], textarea {
      padding:18px;
      border:none;
      border-radius: 8px;
      min-width: none;
    }
    width:100%;
    label{
      margin-bottom: 5px;
    }
    position: relative;
    input[type=text]{
      padding:18px 18px 18px 43px
    }
  }
  .date-time-div-contact{
    // margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    gap:15px;
    width:100%;
  }
  .referred-by2{
    margin-bottom: 15px;
  }
  .contact-calendar-icon, .contact-clock-icon{
    position: absolute;
    bottom:32px;
    left:18px;
    z-index: 1;
}
.contact-date-caret,.contact-time-caret{
    position: absolute;
    bottom:32px;
    right:18px;
    z-index: 1;
}
.referred-caret-icon2{
  position: absolute;
  bottom:20px;
  right:18px;
  z-index: 1;
}
 @media screen and (min-width:760px){

  .contact-column{
    display: flex;
    flex-direction: row;
    width:100%;
    padding:50px 25px 100px 25px;
    gap:15px;
  }
  .contact-form{
    width:100%;
    padding: 50px;
  }
  .contact-div{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width:100%;
    max-width:500px;
    min-width:300px;
    margin:0px auto;
    height:auto;
    align-self: stretch;
    gap:200px;
    margin-bottom: 0px;
  }
 .contact-specifics-2{
  width:100%;
  min-width:230px;
  max-width:450px;
  height: auto;
  flex-direction:column;
  margin-right:90px;
 }
 .contact-phone-btn, .contact-address-btn{
  margin-top:15px;
 }
 .contact-description {
  font-size: 16px;
  width: 90%;
 }
 .schedule-a-call{
  font-size: 60px;
 }
 .success-msg-header{
  font-size: 48px;
 }

 .success-msg-text{
  font-size: 18px;
}
 }
@media screen and (max-width:342px){
  .calendar-icon{
  display:none;
   }
}
 
 
@media screen and (max-width:1005px){
  .success-msg-text{
    &:last-of-type{
      margin-bottom: 100px;
    }
   }
   .contact-form{
    padding: 25px;
  }
 }
 
 @media screen and (max-width:893px){
  .phone-email-div-contact{
    flex-direction: column;
    gap:0px;
  }
  .date-time-div-contact{
    margin-bottom: 15px;
    flex-direction: column;
    gap:0px;
  }
  .referred-by2{
    margin-bottom: 15px;
  }
  .contact-phone, .contact-email{
    width:100%;
  }
  .contact-time-caret, .contact-clock-icon{
    bottom:16px;
  }
 }
 