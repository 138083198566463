

.commercial-block {
    padding: 50px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .commercial-block-body {
    // margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    // width: 70%;
    // margin: auto;
  
  }
  .commercial-video {
    font-family: "Proxima Nova";
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    color: $terracotta;
    text-transform: uppercase;
    margin-bottom: 5px;
    letter-spacing: 1.6px;
    width:100%;
  }

  .the-latest-of-corso {
    font-family: "Playfair Display";
    font-size: 34px;
    font-weight: 400;
    line-height: normal;
    color: $black;
    width:100%;
  }
  .commercial-block-img {
    width: 100%;
    max-width: 800px;
    height: auto;
    margin: 25px 0px;
    border-radius: 8px;
  }
  .request-info-div {
    background-color: $terracotta;
    border-radius: 8px;
    padding: 18px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: stretch;
  }
  .request-info {
    font-family: "Proxima Nova";
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
    color: $white;
    text-transform: uppercase;
    letter-spacing: 2.7px;
  }

  .vid-container{
    width:100%;
    height:auto;
    min-width: 300px;
    min-height: 200px;
    max-width: 800px;
    margin:50px 0px;
    position: relative;
  }
  .vid-container #Capa_1{
    position: absolute;
    top:35%;
    left:45%;
    width: 12%;
    height: auto;
  }
  .video-js, .commercial-vid{
    width:100%;
    height:auto;
    min-width: 300px;
    min-height: 200px;
    // aspect-ratio: 16/9;
    border-radius: 8px;
    max-width: 900px;
    
  }
  // .vjs-tech{
  //   border-radius: 8px;
  // }
  .vjs-theme-fantasy {
    --vjs-theme-fantasy--primary: rgba(216, 131, 102, 1);
    border-radius: 8px;
}
// .vjs-icon-placeholder{
//   &::before{
//     color: white;
   
//   }
// }
// .video-js .vjs-big-play-button .vjs-icon-placeholder:before {
//   content: "";
//   size: 200px;
//   background-image: url('../landing_page/icons/play-button-svgrepo-com.svg');
//   background-repeat: no-repeat;
//   color: #ffffff;
//   background-size: 86px;
//   background-position: 55% calc(50% - 0px);
//   border: none !important;
//   box-shadow: none !important;
// }
  @media screen and (min-width:960px) {
.commercial-block {
  padding: 100px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap:50px;
  max-width: 1700px;
  margin: auto;
}
.commercial-block-body {
  align-self: normal;
  width: 45%;
  justify-content: space-between;
  gap:15px;
  margin: 0px;
  
}
.vid-container{
  width:55%;
}

.the-latest-of-corso {
  font-size: 3.6vw;  
}
.commercial-block-img {
 width:60%;
  height: auto;
  margin:0px;
  border-radius: 8px;
  max-width: 900px;
}
.commercial-video {
  font-size: 20px;
  font-weight: 700;
  color: $terracotta;
  text-transform: uppercase;
  margin-bottom: 5px;
  letter-spacing: .15em;
}
.video-js{
  width:60%;
  height:auto;
  min-width: 300px;
  min-height: 200px;
  aspect-ratio: 16/9;
  border-radius: 8px;
  max-width: 900px;
  margin:0px auto;
}
  }

  @media screen and (min-width:1376px) {
    .the-latest-of-corso {
      font-size: 60px;
    }
  }