.vector {
    width: 100%;
    &:not(:last-of-type) {
      margin-bottom: 8.75px;
    }
  }
  .frame7 {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 25px;
  border-bottom: 1px solid $grey-1;
  flex: none;
  order: 1;
  flex-grow: 0;
  }

  .corso-brandmark-white {
    width: 120px;
    height: 19.4px;
    flex: none;
  order: 0;
  flex-grow: 0;
  }
  .navbar {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items:center;
    padding: 0px 50px 0px 50px;
    flex-wrap: nowrap;
    gap: 40px;
    width:100vw;
    border-bottom: .75px solid #000000;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    }
    .corso-logo {
      width: 120px;
      height: 19.4px;
     margin:20px 0px 0px 0px;
    }
    .navbar-buttons {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px;
      justify-content: space-between;
      // gap: 30px;
      width:80%;
      // width: 1069px;
      // height: 54px;
      flex: none;
      order: 1;
      flex-grow: 0;
    }
    .nav-link {
    font-family: 'Proxima Nova';
    float: left;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: $black;
  //   flex: none;
  //   order: 5;
  //   flex-grow: 0;
  //    margin: 0;
  //  padding: 0;
  //  border: 0;
    &:hover{
      color:$terracotta;
    }
    }
    .navbar-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px 36px;
    gap: 10px;
    width:228px;
    height:54px;
    border: none;
    background: $terracotta;
    border-radius: 8px;
    /* Inside auto layout */
    flex: none;
    order: 6;
    flex-grow: 0;
    }
    .navbar-btn-text {
   
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
    }
   
    // .expanded-container{
    //   background: $grey-2;
    // }
    .navbar-buttons-expanded{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      padding: 50px;
      gap: 25px;
      width:350px;
      margin: auto;
      // background: $grey-2;
      flex: none;
      order: 2;
      align-self: stretch;
      flex-grow: 0;
    }
    .link-expanded{
      font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: $black;
    flex: none;
    order: 0;
    flex-grow: 0;
    text-decoration: none;
    &:hover{
      color:$terracotta;
        }
    }
 
    .nav-divider{
    width: 290px;
    height: 0px;
    opacity: 0.25;
    border-top: 1px solid $black;
    flex: none;
    align-self: stretch;
    flex-grow: 0;
    }
    .navbar-btn-expanded{
      display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px 36px;
    gap: 10px;
    width: 290px;
    height: 54px;
    background: $terracotta;
    border-radius: 8px;
    flex: none;
    order: 12;
    align-self: stretch;
    flex-grow: 0;
    }
    .navbar-btn-text-expanded{
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: $white;
    flex: none;
    order: 0;
    flex-grow: 0;
    }
   
    @media only screen and (max-width: 1217px) and (min-width: 1000px){
   
      .navbar-btn{
        padding: 16px 0px;
        width:170px;
      }
    .nav-link{
      font-size: 13px;
     
    letter-spacing: 0.1em;
    }
    }
 