.accolades {
    background-color: rgba(65, 88, 102, 1);
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .accolades-header {
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .accolades-header-sub {
    font-family: "Proxima Nova";
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    color: $terracotta;
    text-transform: uppercase;
    margin-bottom: 5px;
    letter-spacing: 1.6px;
    overflow: hidden;
  }
  .accolades-header-main {
    font-family: "Playfair Display";
    font-size: 34px;
    font-weight: 400;
    line-height: normal;
    color: $white;
    font-style: italic;
    overflow: hidden;
  }
  .accolades-body {
    border-radius: 8px;
    padding: 24px;
    margin:auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    align-self: stretch;
    border: 1px solid $white;
  }

  .accolades-footer1, .accolades-footer2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &:not(:last-of-type) {
      margin-bottom: 50px;
    }
  }
  .accolades-title, .accolades-footer-title {
    font-family: "Playfair Display";
    font-size: 24px;
    font-weight: 400;
    line-height: normal;
    color: $white;
    align-self: stretch;
    margin-bottom: 15px;
  }
  .accolades-subtitle1,.accolades-subtitle2 {
    font-family:  "Playfair Display";
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    color: $white;
    font-style: italic;
  }
  // .divider {
  //   align-self: stretch;
  //   margin-bottom: 50px;
  //   object-fit: cover;

  // }
  .accolades-divider{
    margin-bottom: 50px;
    border-top:.5px solid white;
    height:0px;
    width:100%;
  }


  @media screen and (min-width:760px){

    .accolades-body {
      padding: 50px;
      margin:0px;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
    }
    .accolades-divider{
      border-left:1px solid white;
      height:70px;
      width:0px;
      margin:0px 50px;
      align-self: center;
    }


  .accolades-footer1, .accolades-footer2 {
    width:50%;
    &:not(:last-of-type) {
      margin-bottom: 0px;
    }
  }
  }