html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, .p-copy, .div-copy, .a-copy {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.corso-building-img {
  width: 100%;
  height: 90vh;
  -o-object-fit: cover;
     object-fit: cover;
}

.request-info-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 0px;
  gap: 10px;
  border: none;
  width: 281px;
  height: 56px;
  background: #D88366;
  border-radius: 8px;
  flex: none;
  order: 2;
  flex-grow: 0;
  text-decoration: none !important;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: white;
}
.request-info-btn:hover {
  color: white;
}
.request-info-btn :link {
  text-decoration: none !important;
}

a:link {
  text-decoration: none;
}

@media screen and (max-width: 760px) {
  .corso-building-img {
    width: 100%;
    height: auto;
    min-height: 300px;
    max-height: 500px;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@keyframes smoothPadding {
  from {
    padding: 0px 50px;
  }
  to {
    padding: 0px 0px;
  }
}
.about-us-block {
  padding: 50px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  flex: none;
  order: 5;
  align-self: stretch;
  flex-grow: 0;
}

.about-us-header {
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 5px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.about-us {
  font-family: "Proxima Nova";
  font-size: 16px;
  color: rgb(216, 131, 102);
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 5px;
  line-height: 19px;
  letter-spacing: 0.1em;
}

.your-experience-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  padding: 0px;
  margin-bottom: 50px;
  width: 85vw;
}

.your-experience {
  font-family: "Playfair Display";
  text-align: center;
  font-size: 34px;
  padding: 0px;
  font-weight: 400;
  line-height: normal;
  color: rgb(0, 0, 0);
  margin-bottom: 10px;
}

.about-us-divider {
  padding: 0px 25px 25px;
  height: 1px;
  border-top: 1px solid rgb(199, 193, 186);
  width: 100%;
  max-width: 600px;
  margin: auto;
}
.about-us-divider:last-of-type {
  padding: 0px;
}

.about-us-divider1 {
  height: 1px;
  border-top: 1px solid rgb(199, 193, 186);
  width: 91%;
  margin: 0px auto;
}

.about-us-cards {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.about-us-card {
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.about-us-card-img {
  width: 100%;
  max-width: 800px;
  height: auto;
  margin: auto;
  border-radius: 8px;
}

.about-us-card-body {
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  align-self: stretch;
}

.about-us-card-header {
  width: 100%;
  max-width: 1000px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
}

.about-us-card-title {
  width: 290px;
  font-family: "Playfair Display";
  font-size: 34px;
  font-weight: 400;
  line-height: normal;
  color: rgb(0, 0, 0);
  margin-bottom: 15px;
}

.about-us-card-sub {
  margin-bottom: 50px;
  width: 100%;
  max-width: 600px;
  font-family: "Proxima Nova";
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  color: rgb(0, 0, 0);
}

.about-us-card-footer {
  border-radius: 8px;
  padding: 13px 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(0, 0, 0);
  text-decoration: none;
}

.learn-more-link {
  font-family: "Proxima Nova";
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  color: rgb(0, 0, 0);
  text-transform: uppercase;
  margin-right: 5px;
  letter-spacing: 1.25px;
}

.frame-divider {
  padding: 3.75px;
  width: 7.5px;
  height: 7.5px;
  position: relative;
}

.vector {
  width: 100%;
  height: 100%;
  position: relative;
}

.vectorTwo {
  width: 40.6%;
  height: 40.6%;
  position: absolute;
  left: 5.16px;
  top: 3.75px;
}

.frame39467Two {
  background-color: rgb(216, 131, 102);
  border-radius: 8px;
  padding: 18px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: stretch;
}

.request-info {
  padding: 16px 54px;
  gap: 10px;
  border: none;
  width: 281px;
  height: 56px;
  background: #D88366;
  border-radius: 8px;
}

@media screen and (min-width: 760px) {
  .about-us {
    text-align: start;
  }
  .about-us-divider {
    padding: 0px;
    max-width: none;
  }
  .about-us-block {
    align-items: flex-start;
    padding: 100px;
    order: 4;
    align-self: normal;
    flex-grow: 0;
  }
  .about-us-header {
    align-items: flex-start;
    margin-bottom: 0px;
    gap: 15px;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .your-experience {
    font-weight: 400;
    font-size: 320%;
    line-height: 80px;
  }
  .request-info {
    padding: 16px 54px;
    gap: 10px;
    border: none;
    width: 281px;
    height: 56px;
    background: #D88366;
    border-radius: 8px;
  }
  .about-us-cards {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 50px;
    gap: 50px;
    width: 100%;
  }
  .about-us-card {
    background-color: rgb(255, 255, 255);
    padding: 0px;
    gap: 50px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: normal;
    width: 100%;
  }
  .about-us-card-img {
    width: 45%;
    height: auto;
    border-radius: 8px;
  }
  .about-us-card-body {
    align-self: stretch;
    width: 100%;
    justify-content: space-between;
    align-items: normal;
    padding: 0px;
    gap: 25px;
  }
  .about-us-card-title {
    font-size: 48px;
    font-weight: 400;
    width: 100%;
  }
  .about-us-card-sub {
    font-size: 18px;
    max-width: 100%;
  }
  .about-us-card-footer {
    align-self: flex-end;
  }
}
@media screen and (min-width: 760px) and (max-width: 1075px) {
  .about-us-cards {
    padding: 0px;
  }
  .about-us-block {
    padding: 60px 40px;
  }
  .about-us-divider1 {
    width: 100%;
  }
}
.values-banner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 25px;
  background: #415866;
  flex: none;
  order: 3;
  gap: 0.6rem;
  align-self: stretch;
  flex-grow: 0;
}

.values-banner-text, .values-banner-italics {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  text-transform: capitalize;
  color: #FFFFFF;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.values-banner-italics {
  font-style: italic;
}

@media screen and (min-width: 760px) {
  .values-banner {
    padding: 50px;
    gap: 0.6rem;
  }
  .values-banner-text, .values-banner-italics {
    font-size: 36px;
    line-height: 48px;
  }
}
.hero-column {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
  height: auto;
  flex: none;
  gap: 50px;
  order: 0;
  flex-grow: 0;
}

.hero-information {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 50px 25px;
  gap: 40px;
  background: rgb(238, 236, 230);
  flex: none;
  align-self: stretch;
  flex-grow: 0;
}

.hero-title {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 64px;
  text-align: center;
  color: #000000;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.contact-specifics {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 15px;
  flex: none;
  align-self: stretch;
  flex-grow: 0;
}

.email-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 15px;
  width: 52px;
  height: 52px;
  border: 1px solid #000000;
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.email-text {
  color: black;
}

.email-button2, .phone-button2, .address-button2 {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 22px;
  gap: 15px;
  border: 1px solid #000000;
  border-radius: 8px;
  flex: none;
  align-self: stretch;
  flex-grow: 0;
  text-decoration: none !important;
}

.phone-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14px;
  gap: 15px;
  width: 52px;
  height: 52px;
  border: 1px solid #000000;
  border-radius: 8px;
  flex: none;
  order: 1;
  text-decoration: none !important;
  flex-grow: 0;
}

.address-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px;
  gap: 15px;
  width: 52px;
  height: 52px;
  border: 1px solid #000000;
  border-radius: 8px;
  flex: none;
  order: 2;
  flex-grow: 0;
  text-decoration: none !important;
}

.quiz-modal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 340px;
  height: auto;
  padding: 25px;
  gap: 25px;
  margin: auto;
  background: #FFFFFF;
  border-radius: 12px;
}

.quiz-modal-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 15px;
  width: 100%;
  max-width: 340px;
  height: auto;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.one-minute-care-quiz {
  font-family: "Proxima Nova";
  font-size: 12px;
  font-weight: 600;
  padding: 0px;
  line-height: normal;
  color: rgb(0, 0, 0);
  text-transform: uppercase;
  margin-bottom: 15px;
  letter-spacing: 1.25px;
}

.hero-divider {
  height: 1px;
  border-top: 1px solid rgb(238, 236, 230);
  width: 100%;
}

.question {
  width: 100%;
  max-width: 340px;
  height: auto;
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: rgb(0, 0, 0);
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.take-quiz-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 0px;
  gap: 10px;
  width: 100%;
  max-width: 290px;
  height: auto;
  background: rgb(216, 131, 102);
  border-radius: 8px;
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
}

.take-the-quiz {
  font-family: "Proxima Nova";
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  letter-spacing: 2.7px;
}

.quiz-card {
  width: 100%;
  max-width: 2000px;
  height: auto;
  border-radius: 8px;
}

.quiz-div-mobile {
  width: 100%;
  max-width: 700px;
  height: auto;
}

@media screen and (min-width: 875px) {
  .hero-block {
    background-color: rgb(238, 236, 230);
    padding: 100px 73px 100px 73px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 100px;
    height: 100%;
    background: rgb(238, 236, 230);
  }
  .hero-information {
    align-items: flex-start;
    padding: 0px;
    background: rgb(238, 236, 230);
    width: 41%;
    height: auto;
    max-height: 100vh;
    min-height: none;
  }
  .quiz-div {
    width: 55.24%;
    display: flex;
    align-self: stretch;
  }
  .hero-title {
    font-size: 78px;
    line-height: normal;
    align-self: stretch;
    width: 100%;
    max-width: 900px;
    text-align: left;
  }
  .contact-specifics {
    flex-direction: column;
    justify-content: normal;
    align-self: stretch;
    max-width: 600px;
  }
}
@media screen and (max-width: 1040px) and (min-width: 875px) {
  .hero-title {
    font-size: 48px;
    line-height: normal;
  }
  .hero-block {
    padding: 50px;
  }
}
@media screen and (max-width: 1420px) and (min-width: 1040px) {
  .hero-title {
    font-size: 58px;
    line-height: normal;
  }
  .hero-block {
    padding: 50px;
  }
}
@media screen and (min-width: 2200px) {
  .hero-information {
    align-items: center;
  }
  .contact-specifics {
    width: 100%;
    margin: auto;
  }
}
.vector {
  width: 100%;
}
.vector:not(:last-of-type) {
  margin-bottom: 8.75px;
}

.frame7 {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 25px;
  border-bottom: 1px solid rgb(199, 193, 186);
  flex: none;
  order: 1;
  flex-grow: 0;
}

.corso-brandmark-white {
  width: 127.87px;
  height: 35px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.navbar {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 50px 0px 50px;
  flex-wrap: nowrap;
  gap: 40px;
  width: 100vw;
  border-bottom: 0.75px solid #000000;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.corso-logo {
  width: 127.87px;
  height: 35px;
  margin: 20px 0px 0px 0px;
}

.navbar-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  justify-content: space-between;
  width: 80%;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.nav-link {
  font-family: "Proxima Nova";
  float: left;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: rgb(0, 0, 0);
}
.nav-link:hover {
  color: rgb(216, 131, 102);
}

.navbar-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 36px;
  gap: 10px;
  width: 228px;
  height: 54px;
  border: none;
  background: rgb(216, 131, 102);
  border-radius: 8px;
  /* Inside auto layout */
  flex: none;
  order: 6;
  flex-grow: 0;
}

.navbar-btn-text {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: #FFFFFF;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.navbar-buttons-expanded {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 50px;
  gap: 25px;
  width: 350px;
  margin: auto;
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
}

.link-expanded {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: rgb(0, 0, 0);
  flex: none;
  order: 0;
  flex-grow: 0;
  text-decoration: none;
}
.link-expanded:hover {
  color: rgb(216, 131, 102);
}

.nav-divider {
  width: 290px;
  height: 0px;
  opacity: 0.25;
  border-top: 1px solid rgb(0, 0, 0);
  flex: none;
  align-self: stretch;
  flex-grow: 0;
}

.navbar-btn-expanded {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 36px;
  gap: 10px;
  width: 290px;
  height: 54px;
  background: rgb(216, 131, 102);
  border-radius: 8px;
  flex: none;
  order: 12;
  align-self: stretch;
  flex-grow: 0;
}

.navbar-btn-text-expanded {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  flex: none;
  order: 0;
  flex-grow: 0;
}

@media only screen and (max-width: 1217px) and (min-width: 1000px) {
  .navbar-btn {
    padding: 16px 0px;
    width: 170px;
  }
  .nav-link {
    font-size: 13px;
    letter-spacing: 0.1em;
  }
}
.residences {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 25px;
}

.residences-body {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: normal;
  width: 100%;
  min-width: 280px;
  height: auto;
  padding: 0px;
  gap: 35px;
  margin: 50px 0px;
}

.residences-sub {
  width: 110px;
  font-family: "Proxima Nova";
  font-size: 16px;
  font-weight: 600px;
  line-height: normal;
  color: rgb(216, 131, 102);
  text-transform: uppercase;
  margin-bottom: 15px;
  letter-spacing: 0.1em;
}

.residences-title {
  font-family: "Playfair Display";
  font-size: 34px;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  color: rgb(0, 0, 0);
}

.residences-body-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  min-width: 315px;
  height: auto;
  margin: auto;
  padding: 0px;
}

.residences-header {
  gap: 5px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.city-homes-two {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  align-self: stretch;
  padding: 35px 0px;
  text-decoration: none !important;
}
.city-homes-two:hover {
  color: #D88366;
}

.residences-two {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 25px;
  gap: 50px;
  flex: none;
  order: 4;
  align-self: stretch;
  flex-grow: 0;
}

.frame4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 5px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.residences {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.1em;
  color: rgb(216, 131, 102);
}

.num01 {
  width: 25px;
  height: 15.14px;
}

.city-homes {
  font-family: "Playfair Display";
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  color: rgb(0, 0, 0);
  text-decoration: none !important;
}
.city-homes:hover {
  color: #D88366;
}

.city-homes-image1 {
  width: 50%;
  max-width: 600px;
  height: auto;
  align-self: center;
  border-radius: 8px;
}

.num02 {
  width: 25px;
  height: 13.83px;
}

.num03 {
  width: 25px;
  height: 17.81px;
}

.num04 {
  width: 25px;
  height: 16.66px;
}

.request-info {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: #FFFFFF;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.residences-divider {
  border-top: 1px solid rgb(199, 193, 186);
}

.residences-cards {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.residences-card {
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.residences-card-img {
  width: 100%;
  max-width: 710px;
  height: auto;
  margin: auto;
}

.residences-card-body {
  padding: 35px 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  align-self: stretch;
}

.residences-card-header {
  width: 100%;
  max-width: 600px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
}

.residences-card-title {
  width: 100%;
  font-family: "Playfair Display";
  font-weight: 400;
  font-size: 24px;
  line-height: normal;
  text-transform: capitalize;
  color: rgb(0, 0, 0);
  margin-bottom: 25px;
}

.floorplans-btn {
  border-radius: 8px;
  padding: 13px 0px;
  width: 80%;
  max-width: 250px;
  min-width: 192px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  justify-content: center;
  border: 1px solid rgb(0, 0, 0);
  text-decoration: none !important;
}

.floorplans-btn-text {
  font-family: "Proxima Nova";
  font-size: 14px;
  font-weight: 600;
  color: rgb(0, 0, 0);
  text-transform: uppercase;
}

.residences-card-divider {
  padding: 25px 0px;
  height: 1px;
  border-top: 1px solid rgb(199, 193, 186);
  width: 100%;
}
.residences-card-divider:last-of-type {
  padding: 0px;
}

@media screen and (min-width: 834px) {
  .residences-divider {
    width: 100%;
    border-top: 1px solid rgb(199, 193, 186);
  }
  .residences {
    padding: 100px;
  }
  .residences-body {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 50px;
    margin: 100px;
  }
  .residences-body-text {
    width: 50%;
  }
  .residences-sub {
    width: 174px;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 3px;
  }
  .residences-title {
    font-size: 60px;
    gap: 15px;
  }
}
@media screen and (max-width: 1126px) {
  .residences {
    padding: 40px;
  }
}
@media screen and (min-width: 1800px) {
  .residences-body-text {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 50%;
    margin: auto 0px;
  }
  .city-homes-image1 {
    max-width: 1000px;
    height: auto;
    align-self: center;
  }
}
.testimonial-block {
  padding: 50px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.testimonial-header {
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.testimonial-header-sub {
  font-family: "Proxima Nova";
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  color: rgb(216, 131, 102);
  text-transform: uppercase;
  margin-bottom: 5px;
  letter-spacing: 1.6px;
}

.testimonial-header-text {
  font-family: "Playfair Display";
  font-size: 34px;
  font-weight: 400;
  line-height: normal;
  color: rgb(0, 0, 0);
}

.testimonial-card {
  background-color: rgb(65, 88, 102);
  margin-bottom: 25px;
  border-radius: 8px;
  display: flex;
  margin: auto;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.testimonial-video-div {
  height: 100%;
  width: 100%;
  position: relative;
}

#Capa_1 {
  position: absolute;
  top: 40%;
  left: 45%;
  width: 12%;
  height: auto;
}

.testimonial-img {
  width: 100%;
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
  align-self: stretch;
  border-radius: 8px 8px 0px 0px;
}

.testimonial-card-body {
  padding: 6vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  align-self: stretch;
}

.testimonial-stars {
  width: 70px;
  height: 10px;
  margin-bottom: 25px;
}

.testimonial-card-quote {
  font-family: "Playfair Display";
  font-size: 20px;
  font-weight: 400;
  width: 100%;
  line-height: normal;
  color: rgb(255, 255, 255);
  align-self: stretch;
  margin-bottom: 25px;
}

.testimonial-client-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.testimonial-client-name {
  font-family: "Proxima Nova";
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  color: rgb(255, 255, 255);
  margin-bottom: 5px;
}

.testimonial-client-date {
  font-family: "Proxima Nova";
  font-size: 10px;
  font-weight: 400;
  line-height: normal;
  color: rgb(255, 255, 255);
}

.testimonial-page-dots {
  margin: 25px auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.rectangle654 {
  width: 8px;
  height: 8px;
  margin-right: 8px;
  border-radius: 20px;
}

.rectangle657 {
  width: 8px;
  height: 8px;
  border-radius: 20px;
}
.rectangle657:not(:last-of-type) {
  margin-right: 8px;
}

.testimonial-buttons {
  margin-bottom: 50px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 340px;
}

.left-btn {
  border-radius: 50px;
  padding: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
}
.left-btn:not(:last-of-type) {
  margin-right: 15px;
}
.left-btn:hover {
  cursor: pointer;
}

.left-arrow, .right-arrow {
  position: relative;
}
.left-arrow:hover, .right-arrow:hover {
  cursor: pointer;
}

.request-info {
  font-family: "Proxima Nova";
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  letter-spacing: 2.7px;
}

@media screen and (min-width: 897px) {
  .testimonial-block1 {
    background-color: rgb(255, 255, 255);
    padding: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .testimonial-header1 {
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
  }
  .testimonial-header-sub1 {
    font-family: "Proxima Nova";
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
    color: rgb(216, 131, 102);
    text-transform: uppercase;
    margin-bottom: 15px;
    letter-spacing: 3px;
  }
  .testimonial-header-group1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-self: stretch;
  }
  .testimonial-header-text1 {
    font-family: "Playfair Display";
    font-size: 60px;
    font-weight: 400;
    line-height: normal;
    color: rgb(0, 0, 0);
  }
  .testimonial-body1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .testimonial-card1 {
    background-color: rgb(65, 88, 102);
    margin-bottom: 18px;
    border-radius: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    max-width: 2000px;
    height: auto;
    min-height: 250px;
  }
  .testimonial-card-body1 {
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: center;
    width: 75%;
  }
  .testimonial-stars1 {
    width: 115px;
    height: 15px;
    margin-bottom: 25px;
  }
  .testimonial-card-quote1 {
    font-family: "Playfair Display";
    font-size: 34px;
    font-weight: 400;
    width: 100%;
    line-height: normal;
    color: rgb(255, 255, 255);
    align-self: stretch;
    margin-bottom: 25px;
    margin-bottom: 20px;
  }
  .testimonial-client-info1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
  }
  .testimonial-client-name1 {
    font-family: "Proxima Nova";
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    color: rgb(255, 255, 255);
    margin-bottom: 5px;
  }
  .testimonial-client-date1 {
    font-family: "Proxima Nova";
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    color: rgb(255, 255, 255);
  }
  .testimonial-card-img1 {
    width: 100%;
    height: 100%;
    min-height: 420px;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 0px 16px 16px 0px;
    align-self: center;
    position: static;
  }
  .testimonial-video-div {
    height: 100%;
    width: 100%;
    position: relative;
  }
  #Capa_1 {
    position: absolute;
    top: 35%;
    left: 43%;
    height: 90px;
    width: auto;
  }
  .testimonial-page-dots1 {
    margin-bottom: 50px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  .rectangle6541 {
    width: 8px;
    height: 8px;
    margin-right: 8px;
    border-radius: 20px;
  }
  .rectangle6571 {
    width: 8px;
    height: 8px;
    border-radius: 20px;
  }
  .rectangle6571:not(:last-of-type) {
    margin-right: 8px;
  }
  .testimonial-buttons1 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    width: 100%;
  }
  .left-btn1 {
    border-radius: 50px;
    padding: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
  }
  .left-btn1:not(:last-of-type) {
    margin-right: 25px;
  }
  .left-btn1:hover {
    cursor: pointer;
  }
  .left-arrow1, .right-arrow1 {
    position: relative;
  }
}
@media screen and (max-width: 1255px) and (min-width: 897px) {
  .testimonial-card-quote1 {
    font-size: 34px;
  }
  .testimonial-card-body1 {
    padding: 50px;
  }
  .testimonial-card-img1 {
    min-width: 300px;
  }
  .testimonial-block1 {
    padding: 100px 50px;
  }
  .testimonial-header-text1 {
    font-size: 42px;
  }
  .request-info {
    width: 250px;
  }
}
.mobile-banner-div {
  display: flex;
  flex-direction: column;
  padding: 0px;
  z-index: -1;
  flex: none;
  order: 3;
  align-self: stretch;
  height: 100px;
  width: 100%;
  background-image: url(../landing_page/imgs/mobilePattern2.png);
  flex-grow: 0;
}

.banner-pattern {
  z-index: 999;
  height: 82px;
  width: 100vw;
  -o-object-fit: cover;
     object-fit: cover;
  background-repeat: repeat-x;
}

@keyframes smoothPadding {
  from {
    padding: 0px 50px;
  }
  to {
    padding: 0px 0px;
  }
}
.about-us-block {
  padding: 50px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  flex: none;
  order: 5;
  align-self: stretch;
  flex-grow: 0;
}

.about-us-header {
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 5px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.about-us {
  font-family: "Proxima Nova";
  font-size: 16px;
  color: rgb(216, 131, 102);
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 5px;
  line-height: 19px;
  letter-spacing: 0.1em;
}

.your-experience-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  padding: 0px;
  margin-bottom: 50px;
  width: 85vw;
}

.your-experience {
  font-family: "Playfair Display";
  text-align: center;
  font-size: 34px;
  padding: 0px;
  font-weight: 400;
  line-height: normal;
  color: rgb(0, 0, 0);
  margin-bottom: 10px;
}

.about-us-divider {
  padding: 0px 25px 25px;
  height: 1px;
  border-top: 1px solid rgb(199, 193, 186);
  width: 100%;
  max-width: 600px;
  margin: auto;
}
.about-us-divider:last-of-type {
  padding: 0px;
}

.about-us-divider1 {
  height: 1px;
  border-top: 1px solid rgb(199, 193, 186);
  width: 91%;
  margin: 0px auto;
}

.about-us-cards {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.about-us-card {
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.about-us-card-img {
  width: 100%;
  max-width: 800px;
  height: auto;
  margin: auto;
  border-radius: 8px;
}

.about-us-card-body {
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  align-self: stretch;
}

.about-us-card-header {
  width: 100%;
  max-width: 1000px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
}

.about-us-card-title {
  width: 290px;
  font-family: "Playfair Display";
  font-size: 34px;
  font-weight: 400;
  line-height: normal;
  color: rgb(0, 0, 0);
  margin-bottom: 15px;
}

.about-us-card-sub {
  margin-bottom: 50px;
  width: 100%;
  max-width: 600px;
  font-family: "Proxima Nova";
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  color: rgb(0, 0, 0);
}

.about-us-card-footer {
  border-radius: 8px;
  padding: 13px 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(0, 0, 0);
  text-decoration: none;
}

.learn-more-link {
  font-family: "Proxima Nova";
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  color: rgb(0, 0, 0);
  text-transform: uppercase;
  margin-right: 5px;
  letter-spacing: 1.25px;
}

.frame-divider {
  padding: 3.75px;
  width: 7.5px;
  height: 7.5px;
  position: relative;
}

.vector {
  width: 100%;
  height: 100%;
  position: relative;
}

.vectorTwo {
  width: 40.6%;
  height: 40.6%;
  position: absolute;
  left: 5.16px;
  top: 3.75px;
}

.frame39467Two {
  background-color: rgb(216, 131, 102);
  border-radius: 8px;
  padding: 18px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: stretch;
}

.request-info {
  padding: 16px 54px;
  gap: 10px;
  border: none;
  width: 281px;
  height: 56px;
  background: #D88366;
  border-radius: 8px;
}

@media screen and (min-width: 760px) {
  .about-us {
    text-align: start;
  }
  .about-us-divider {
    padding: 0px;
    max-width: none;
  }
  .about-us-block {
    align-items: flex-start;
    padding: 100px;
    order: 4;
    align-self: normal;
    flex-grow: 0;
  }
  .about-us-header {
    align-items: flex-start;
    margin-bottom: 0px;
    gap: 15px;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .your-experience {
    font-weight: 400;
    font-size: 320%;
    line-height: 80px;
  }
  .request-info {
    padding: 16px 54px;
    gap: 10px;
    border: none;
    width: 281px;
    height: 56px;
    background: #D88366;
    border-radius: 8px;
  }
  .about-us-cards {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 50px;
    gap: 50px;
    width: 100%;
  }
  .about-us-card {
    background-color: rgb(255, 255, 255);
    padding: 0px;
    gap: 50px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: normal;
    width: 100%;
  }
  .about-us-card-img {
    width: 45%;
    height: auto;
    border-radius: 8px;
  }
  .about-us-card-body {
    align-self: stretch;
    width: 100%;
    justify-content: space-between;
    align-items: normal;
    padding: 0px;
    gap: 25px;
  }
  .about-us-card-title {
    font-size: 48px;
    font-weight: 400;
    width: 100%;
  }
  .about-us-card-sub {
    font-size: 18px;
    max-width: 100%;
  }
  .about-us-card-footer {
    align-self: flex-end;
  }
}
@media screen and (min-width: 760px) and (max-width: 1075px) {
  .about-us-cards {
    padding: 0px;
  }
  .about-us-block {
    padding: 60px 40px;
  }
  .about-us-divider1 {
    width: 100%;
  }
}
.commercial-block {
  padding: 50px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.commercial-block-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.commercial-video {
  font-family: "Proxima Nova";
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  color: rgb(216, 131, 102);
  text-transform: uppercase;
  margin-bottom: 5px;
  letter-spacing: 1.6px;
  width: 100%;
}

.the-latest-of-corso {
  font-family: "Playfair Display";
  font-size: 34px;
  font-weight: 400;
  line-height: normal;
  color: rgb(0, 0, 0);
  width: 100%;
}

.commercial-block-img {
  width: 100%;
  max-width: 800px;
  height: auto;
  margin: 25px 0px;
  border-radius: 8px;
}

.request-info-div {
  background-color: rgb(216, 131, 102);
  border-radius: 8px;
  padding: 18px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: stretch;
}

.request-info {
  font-family: "Proxima Nova";
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  letter-spacing: 2.7px;
}

.vid-container {
  width: 100%;
  height: auto;
  min-width: 300px;
  min-height: 200px;
  max-width: 800px;
  margin: 50px 0px;
  position: relative;
}

.vid-container #Capa_1 {
  position: absolute;
  top: 35%;
  left: 45%;
  width: 12%;
  height: auto;
}

.video-js, .commercial-vid {
  width: 100%;
  height: auto;
  min-width: 300px;
  min-height: 200px;
  border-radius: 8px;
  max-width: 900px;
}

.vjs-theme-fantasy {
  --vjs-theme-fantasy--primary: rgba(216, 131, 102, 1);
  border-radius: 8px;
}

@media screen and (min-width: 960px) {
  .commercial-block {
    padding: 100px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 50px;
    max-width: 1700px;
    margin: auto;
  }
  .commercial-block-body {
    align-self: normal;
    width: 45%;
    justify-content: space-between;
    gap: 15px;
    margin: 0px;
  }
  .vid-container {
    width: 55%;
  }
  .the-latest-of-corso {
    font-size: 3.6vw;
  }
  .commercial-block-img {
    width: 60%;
    height: auto;
    margin: 0px;
    border-radius: 8px;
    max-width: 900px;
  }
  .commercial-video {
    font-size: 20px;
    font-weight: 700;
    color: rgb(216, 131, 102);
    text-transform: uppercase;
    margin-bottom: 5px;
    letter-spacing: 0.15em;
  }
  .video-js {
    width: 60%;
    height: auto;
    min-width: 300px;
    min-height: 200px;
    aspect-ratio: 16/9;
    border-radius: 8px;
    max-width: 900px;
    margin: 0px auto;
  }
}
@media screen and (min-width: 1376px) {
  .the-latest-of-corso {
    font-size: 60px;
  }
}
.blog-block {
  background-color: rgb(238, 236, 230);
  padding: 50px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0px;
  flex: none;
  order: 13;
  align-self: stretch;
  flex-grow: 0;
}

.blog-block-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 5px;
  overflow: hidden;
}

.blog-posts-div {
  display: flex;
  margin: 25px 0px;
  width: 80%;
  min-width: 300px;
  flex-direction: column;
  justify-content: center;
  gap: 50px;
}

.the-blog {
  font-family: "Proxima Nova";
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  color: rgb(216, 131, 102);
  text-transform: uppercase;
  margin-bottom: 5px;
  letter-spacing: 0.1em;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.recent-posts {
  font-family: "Playfair Display";
  font-size: 34px;
  font-weight: 400;
  line-height: normal;
  color: rgb(0, 0, 0);
  flex: none;
  order: 1;
  flex-grow: 0;
}

.blog-post {
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 500px;
  margin: 10px auto;
}

.blog-image {
  width: 100%;
  height: auto;
  border-radius: 8px 8px 0px 0px;
}

.blog-post-contents {
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  align-self: stretch;
  gap: 5px;
  flex: none;
  height: 60%;
  order: 1;
  background-color: white;
  border-radius: 0px 0px 8px 8px;
}

.blog-post-body {
  margin-bottom: 35px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.blog-date {
  font-family: "Proxima Nova";
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  color: rgb(199, 193, 186);
  margin-bottom: 15px;
}

.blog-title {
  font-family: "Playfair Display";
  font-size: 34px;
  font-weight: 400;
  line-height: normal;
  color: rgb(0, 0, 0);
  margin-bottom: 15px;
}

.blog-description {
  width: 290px;
  font-family: "Proxima Nova";
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  color: rgb(0, 0, 0);
}

.blog-post-footer {
  text-decoration: none !important;
  border-radius: 8px;
  padding: 13px 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(65, 88, 102);
}

.blog-description2 {
  font-family: "Proxima Nova";
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  color: rgb(65, 88, 102);
  text-transform: uppercase;
  margin-right: 5px;
  letter-spacing: 1.25px;
}

.blog-title2 {
  width: 290px;
  font-family: "Playfair Display";
  font-size: 34px;
  font-weight: 400;
  line-height: normal;
  color: rgb(0, 0, 0);
  margin-bottom: 15px;
}

.blog-description-three {
  width: 287px;
  font-family: "Proxima Nova";
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  color: rgb(0, 0, 0);
}

.blog-link {
  background-color: rgb(216, 131, 102);
  border-radius: 8px;
  padding: 18px 0px;
  width: 80%;
  max-width: 300px;
  min-width: 190px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
}

.view-the-blog {
  text-decoration: none !important;
  font-family: "Proxima Nova";
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  letter-spacing: 2.7px;
}

@media screen and (min-width: 760px) {
  .recent-posts {
    font-size: 60px;
    line-height: normal;
  }
}
@media screen and (min-width: 974px) {
  .blog-post {
    min-width: none;
    width: 100%;
    max-width: 500px;
    margin: 50px 25px;
  }
  .blog-post-contents {
    gap: 50px;
    height: 55%;
  }
  .blog-image {
    min-width: none;
    width: 100%;
    max-width: 500px;
  }
  .blog-posts-div {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
    min-width: 250px;
    gap: 0px;
  }
  .blog-block {
    padding: 75px 100px;
    gap: 50px;
  }
  .blog-block-header {
    width: 100%;
    align-items: flex-start;
    padding: 0px 50px;
  }
  .the-blog {
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 0.15em;
  }
  .expanded-blog-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }
  .blog-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 20px;
    gap: 15px;
    background: #D88366;
    border-radius: 8px;
    text-decoration: none !important;
  }
}
@media screen and (min-width: 974px) and (max-width: 1370px) {
  .blog-posts-div {
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 0px 20px;
  }
  .blog-block {
    padding: 25px 20px;
  }
  .blog-post {
    min-width: 280px;
    width: 31.33%;
    max-width: 350px;
    margin: 25px 10px;
  }
  .blog-image {
    min-width: 280px;
    max-width: 350px;
    min-width: none;
  }
  .blog-title {
    font-size: 26px;
  }
}
@media screen and (max-width: 500px) {
  .blog-block {
    padding: 15px 20px;
  }
}
.contact-column {
  background-color: rgb(255, 255, 255);
  padding: 50px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.contact-div {
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: auto;
  min-width: 170px;
  max-width: 500;
}

.contact-info {
  margin: auto;
  width: 100%;
  overflow: hidden;
}

.contact-blurb {
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  min-width: 150px;
  overflow: hidden;
}

.contact-us {
  font-family: "Proxima Nova";
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  color: rgb(216, 131, 102);
  text-transform: uppercase;
  margin-bottom: 5px;
  letter-spacing: 1.6px;
}

.schedule-a-call {
  font-family: "Playfair Display";
  font-size: 34px;
  font-weight: 400;
  line-height: normal;
  color: rgb(0, 0, 0);
}

.contact-description {
  width: 100%;
  font-family: "Proxima Nova";
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  color: rgb(0, 0, 0);
  text-transform: capitalize;
}

.form-input {
  display: flex;
  flex-direction: column;
  padding: 0px;
  gap: 5px;
}

.contact-phone, .contact-email {
  width: 48%;
}

.contact-form {
  display: flex;
  flex-direction: column;
  padding: 25px;
  gap: 25px;
  width: 100%;
  max-width: 600px;
  min-width: 220px;
  height: auto;
  margin: auto;
  background: #EEECE6;
  border-radius: 16px;
  align-self: stretch;
}
.contact-form label {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.contact-column input[type=text], .contact-column input[type=tel], .contact-column input[type=date], .contact-column textarea {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 18px;
  margin-bottom: 15px;
  background: #FFFFFF;
  border: none;
  border-radius: 8px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}
.contact-column input[type=text]:focus, .contact-column input[type=tel]:focus, .contact-column input[type=date]:focus, .contact-column textarea:focus {
  outline: rgb(199, 193, 186);
}
.contact-column input[type=text]:active, .contact-column input[type=tel]:active, .contact-column input[type=date]:active, .contact-column textarea:active {
  outline: rgb(238, 236, 230);
}

.submit-contact-btn {
  padding: 16px 54px;
  gap: 10px;
  width: 100%;
  max-width: 500px;
  height: auto;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #D88366;
  border-radius: 8px;
  flex: none;
  align-self: stretch;
  border: none;
}

.submit-contact-btn-txt {
  padding: 0;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: #FFFFFF;
  flex: none;
  flex-grow: 0;
}

#contact-date::-moz-placeholder, input::-moz-placeholder, textarea::-moz-placeholder {
  color: rgb(199, 193, 186);
}

#contact-date::placeholder, input::placeholder, textarea::placeholder {
  color: rgb(199, 193, 186);
}

.success-msg-header {
  font-family: "Playfair Display";
  font-size: 34px;
  margin-bottom: 25px;
}

.success-msg-header-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 10px;
}

.success-msg-text {
  font-family: "Proxima Nova";
  font-size: 14px;
  margin-bottom: 25px;
}
.success-msg-text:last-of-type {
  margin-bottom: 200px;
}

.date-picker {
  width: 100%;
}
.date-picker:hover {
  cursor: pointer;
}

.calendar-icon {
  position: absolute;
  top: 36px;
  right: 20px;
}

.date-input, .referred-by-div2 {
  position: relative;
}

.referred-by-div2 {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.brochure-checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.brochure-checkbox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}
.brochure-checkbox label {
  position: relative;
  cursor: pointer;
}
.brochure-checkbox label::before {
  content: "";
  background-color: transparent;
  border: 1.8px solid rgb(199, 193, 186);
  border-radius: 3px;
  padding: 7px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 10px;
}

.brochure-checkbox input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 4px;
  left: 7.5px;
  width: 4.5px;
  height: 9px;
  border: solid rgb(199, 193, 186);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.label-div {
  display: flex;
  flex-direction: column;
  margin: 0px !important;
}

.phone-email-div-contact {
  display: flex;
  flex-direction: row;
  gap: 15px;
  flex-wrap: wrap;
  width: 100%;
}

.react-select2__control, .react-select2-referred__control {
  border: none !important;
  border-radius: 8px;
  height: 55px;
  overflow-x: scroll;
}
.react-select2__control ::-webkit-scrollbar, .react-select2-referred__control ::-webkit-scrollbar {
  display: none;
}
.react-select2__control .react-select2__placeholder, .react-select2-referred__control .react-select2__placeholder {
  color: rgb(199, 193, 186) !important;
  padding-left: 24px;
}
.react-select2__control .react-select2-referred__placeholder, .react-select2-referred__control .react-select2-referred__placeholder {
  color: rgb(199, 193, 186) !important;
  padding-left: 0px;
}
.react-select2__control .react-select2__indicator-separator, .react-select2__control .react-select2-referred__indicator-separator, .react-select2__control .react-select2__indicators, .react-select2__control .react-select2-referred__indicators, .react-select2-referred__control .react-select2__indicator-separator, .react-select2-referred__control .react-select2-referred__indicator-separator, .react-select2-referred__control .react-select2__indicators, .react-select2-referred__control .react-select2-referred__indicators {
  display: none;
}

.react-select2__multi-value:first-of-type {
  margin-left: 25px;
}

.react-select2__control--is-focused, .react-select2-referred__control--is-focused, .react-select2__control--menu-is-open, .react-select2-referred__control--menu-is-open {
  border: none;
  box-shadow: none !important;
}

.date-picker-div, .time-picker-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}
.date-picker-div input[type=text], .date-picker-div input[type=tel], .date-picker-div input[type=email], .date-picker-div textarea, .time-picker-div input[type=text], .time-picker-div input[type=tel], .time-picker-div input[type=email], .time-picker-div textarea {
  padding: 18px;
  border: none;
  border-radius: 8px;
  min-width: none;
}
.date-picker-div label, .time-picker-div label {
  margin-bottom: 5px;
}
.date-picker-div input[type=text], .time-picker-div input[type=text] {
  padding: 18px 18px 18px 43px;
}

.date-time-div-contact {
  display: flex;
  flex-direction: row;
  gap: 15px;
  width: 100%;
}

.referred-by2 {
  margin-bottom: 15px;
}

.contact-calendar-icon, .contact-clock-icon {
  position: absolute;
  bottom: 32px;
  left: 18px;
  z-index: 1;
}

.contact-date-caret, .contact-time-caret {
  position: absolute;
  bottom: 32px;
  right: 18px;
  z-index: 1;
}

.referred-caret-icon2 {
  position: absolute;
  bottom: 20px;
  right: 18px;
  z-index: 1;
}

@media screen and (min-width: 760px) {
  .contact-column {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 50px 25px 100px 25px;
    gap: 15px;
  }
  .contact-form {
    width: 100%;
    padding: 50px;
  }
  .contact-div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    max-width: 500px;
    min-width: 300px;
    margin: 0px auto;
    height: auto;
    align-self: stretch;
    gap: 200px;
    margin-bottom: 0px;
  }
  .contact-specifics-2 {
    width: 100%;
    min-width: 230px;
    max-width: 450px;
    height: auto;
    flex-direction: column;
    margin-right: 90px;
  }
  .contact-phone-btn, .contact-address-btn {
    margin-top: 15px;
  }
  .contact-description {
    font-size: 16px;
    width: 90%;
  }
  .schedule-a-call {
    font-size: 60px;
  }
  .success-msg-header {
    font-size: 48px;
  }
  .success-msg-text {
    font-size: 18px;
  }
}
@media screen and (max-width: 342px) {
  .calendar-icon {
    display: none;
  }
}
@media screen and (max-width: 1005px) {
  .success-msg-text:last-of-type {
    margin-bottom: 100px;
  }
  .contact-form {
    padding: 25px;
  }
}
@media screen and (max-width: 893px) {
  .phone-email-div-contact {
    flex-direction: column;
    gap: 0px;
  }
  .date-time-div-contact {
    margin-bottom: 15px;
    flex-direction: column;
    gap: 0px;
  }
  .referred-by2 {
    margin-bottom: 15px;
  }
  .contact-phone, .contact-email {
    width: 100%;
  }
  .contact-time-caret, .contact-clock-icon {
    bottom: 16px;
  }
}
.accolades {
  background-color: rgb(65, 88, 102);
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.accolades-header {
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.accolades-header-sub {
  font-family: "Proxima Nova";
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  color: rgb(216, 131, 102);
  text-transform: uppercase;
  margin-bottom: 5px;
  letter-spacing: 1.6px;
  overflow: hidden;
}

.accolades-header-main {
  font-family: "Playfair Display";
  font-size: 34px;
  font-weight: 400;
  line-height: normal;
  color: rgb(255, 255, 255);
  font-style: italic;
  overflow: hidden;
}

.accolades-body {
  border-radius: 8px;
  padding: 24px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  align-self: stretch;
  border: 1px solid rgb(255, 255, 255);
}

.accolades-footer1, .accolades-footer2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.accolades-footer1:not(:last-of-type), .accolades-footer2:not(:last-of-type) {
  margin-bottom: 50px;
}

.accolades-title, .accolades-footer-title {
  font-family: "Playfair Display";
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  color: rgb(255, 255, 255);
  align-self: stretch;
  margin-bottom: 15px;
}

.accolades-subtitle1, .accolades-subtitle2 {
  font-family: "Playfair Display";
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  color: rgb(255, 255, 255);
  font-style: italic;
}

.accolades-divider {
  margin-bottom: 50px;
  border-top: 0.5px solid white;
  height: 0px;
  width: 100%;
}

@media screen and (min-width: 760px) {
  .accolades-body {
    padding: 50px;
    margin: 0px;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
  .accolades-divider {
    border-left: 1px solid white;
    height: 70px;
    width: 0px;
    margin: 0px 50px;
    align-self: center;
  }
  .accolades-footer1, .accolades-footer2 {
    width: 50%;
  }
  .accolades-footer1:not(:last-of-type), .accolades-footer2:not(:last-of-type) {
    margin-bottom: 0px;
  }
}
.footer-container {
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100vw;
}

.corso-logo {
  width: 127.87px;
  height: 47px;
  margin-bottom: 20px;
}

.footer-header-div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
  margin: 0px auto;
}

.footer-div {
  width: 100%;
}

.social-media-container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}

.facebook-icon {
  margin-right: 14px;
  border-radius: 8px;
  padding: 11.25px 16.37px 11.25px 16.36px;
  display: flex;
  align-self: center;
}

.facebook {
  flex: 1;
  align-self: stretch;
  -o-object-fit: cover;
     object-fit: cover;
}

.instagram-icon {
  border-radius: 8px;
  padding: 11.25px;
  display: flex;
  align-self: center;
}

.instagram {
  width: 22.5px;
  height: 22.5px;
}

.footer-line {
  height: 1px;
  background-color: rgb(199, 193, 186);
  margin-bottom: 25px;
  align-self: stretch;
}

.footer-column {
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer-title {
  font-family: "Playfair Display";
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  color: rgb(0, 0, 0);
  margin-bottom: 25px;
  white-space: nowrap;
}

.footer-description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer-address, .footer-link {
  font-family: "Proxima Nova";
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  color: rgb(0, 0, 0);
  text-decoration: none !important;
  margin-bottom: 25px;
}
.footer-address:hover, .footer-link:hover {
  color: gray;
  cursor: pointer;
}

.footer-phone-number {
  font-family: "Proxima Nova";
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  text-decoration: none !important;
  color: rgb(0, 0, 0);
  margin-bottom: 25px;
}
.footer-phone-number:hover {
  color: gray;
  cursor: pointer;
}

a.footer-email {
  font-family: "Proxima Nova";
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  text-decoration: none !important;
  color: rgb(0, 0, 0);
  text-transform: lowercase;
  text-decoration: underline;
}
a.footer-email:hover {
  color: gray;
  cursor: pointer;
}

.brochure {
  font-family: "Proxima Nova";
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  color: rgb(0, 0, 0);
  text-decoration: underline;
}
.brochure:hover {
  cursor: pointer;
  color: gray;
}

.copyright {
  padding: 14px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  border-top: 1px solid rgb(199, 193, 186);
}
.copyright a {
  color: rgb(199, 193, 186);
}

.copyright-description {
  font-family: "Proxima Nova";
  font-size: 10px;
  font-weight: 400;
  line-height: normal;
  color: rgb(199, 193, 186);
  align-self: stretch;
  text-align: center;
  text-decoration: underline;
}

@media screen and (min-width: 870px) {
  .footer-container {
    width: 100%;
  }
  .footer-columns-div {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 20px;
  }
  .footer-column {
    width: 100%;
    max-width: 600px;
  }
  .footer-div {
    display: flex;
    flex-direction: row;
    gap: 30px;
    justify-content: space-between;
    align-self: stretch;
  }
  .footer-header-div {
    align-self: flex-start;
    flex-direction: column;
    width: 127.87px;
    margin: 0px;
    justify-content: normal;
    gap: 0px;
  }
  .social-media-container {
    margin: 0px auto;
  }
}
@media screen and (max-width: 342px) {
  .footer-header-div {
    flex-direction: column;
    align-items: center;
  }
}
.social-handle-container {
  padding: 100px 50px;
  width: 100%;
}

.social-handle {
  font-family: "Playfair Display";
  font-style: italic;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  margin-bottom: 50px;
}

.social-divider {
  width: 100vw;
  border: 1px solid rgb(238, 236, 230);
  margin-top: 50px;
}

.social-handle-text {
  color: black;
  text-decoration: none;
}
.social-handle-text:hover {
  color: rgb(216, 131, 102);
}

.social-image-banner {
  display: flex;
  flex-direction: row;
  margin: auto;
  width: 100%;
  justify-content: center;
}

.ig-link {
  width: 100%;
  max-width: 300px;
  height: auto;
}

.socialHandleImg {
  width: 100%;
  max-width: 300px;
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
}

.view-ig-btn {
  border-radius: 8px;
  padding: 13px 0px;
  width: 100%;
  max-width: 250px;
  min-width: 152px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  justify-content: center;
  border: 1px solid rgb(0, 0, 0);
  margin: 50px auto 0px auto;
  color: white;
}
.view-ig-btn:active {
  background-color: black;
  color: white;
}

.view-ig-btn-text {
  font-family: "Proxima Nova";
  font-size: 15px;
  font-weight: 600;
  color: rgb(0, 0, 0);
  text-transform: uppercase;
  color: black;
}
.view-ig-btn-text:active {
  color: white;
}

@media screen and (max-width: 760px) {
  .socialHandleImg {
    width: 100%;
    max-width: 700px;
    height: auto;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
.transition-image {
  width: 100%;
  height: auto;
  position: absolute;
  filter: brightness(50%);
  top: 0;
  right: 0;
  z-index: 999;
  border-radius: 0px 0px 1000px 1000px;
}

.corso-logo-transition {
  position: absolute;
  width: 25%;
  height: auto;
  bottom: 0;
  right: -49%;
  z-index: 1000;
  border-radius: 0px 0px 1000px 1000px;
}

.corso-svg {
  height: 500px;
  width: 500px;
}

@media screen and (min-width: 76px) {
  .corso-logo-transition {
    width: 50%;
    top: 200px;
    right: 15%;
  }
}
@keyframes smoothPadding {
  from {
    padding: 0px 50px;
  }
  to {
    padding: 0px 0px;
  }
}
.testcontainer {
  height: 200vh;
  width: 100vw;
}

.testbox {
  width: 100%;
  max-width: 300px;
  height: auto;
  min-height: 300px;
  border: 2px solid red;
}

.quiz-container {
  width: 100%;
  max-width: 1200px;
  height: auto;
  border: 50px solid white;
  background-color: white;
  border-radius: 8px;
}

.custom-quiz-options {
  height: 100%;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  position: relative;
}

.finish-quiz-div {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  width: 100%;
}

.finish-quiz-blurb {
  line-height: 19.5px;
}

.repeat-quiz-btn {
  color: black;
  border: 1px solid black;
  width: 48%;
}

.get-brochure {
  color: white;
  background-color: rgb(216, 131, 102);
  width: 48%;
}

.repeat-quiz-btn, .get-brochure {
  margin: 0px auto;
  border-radius: 8px;
  text-transform: uppercase;
  font-family: "Proxima Nova";
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.15em;
  min-width: 190px;
  font-weight: 600;
  text-align: center;
  align-self: baseline;
}

.last-slide-footer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}

.quiz-options-only {
  font-family: "Proxima Nova";
  font-weight: 400;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  width: 100%;
  margin-bottom: 75px;
}

.custom-quiz-body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  font-family: "Proxima Nova";
  border-radius: 8px;
}

.quiz-divider {
  width: 100%;
  border-top: 1px solid rgb(199, 193, 186);
  margin-top: 15px;
  position: relative;
}

.quiz-contact-us {
  text-decoration: underline !important;
  color: #D88366;
}

.quiz-progress-bar {
  border-top: 2px solid rgb(216, 131, 102);
  position: absolute;
  top: -1px;
  left: 0px;
}

.custom-quiz-header {
  width: 100%;
  padding: 0px;
  background-color: white;
  z-index: 999;
}
.custom-quiz-header h1 {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.7px;
  text-transform: uppercase;
}
.custom-quiz-header .custom-quiz-header-text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
}

.custom-quiz-question, .finish-quiz-heading {
  font-size: 24px;
  text-transform: capitalize;
  font-family: "Playfair Display";
}
.custom-quiz-question h3, .finish-quiz-heading h3 {
  line-height: 35px;
  letter-spacing: 0.2px;
  margin-top: 50px;
  margin-bottom: 35px;
}
.custom-quiz-question p, .finish-quiz-heading p {
  font-family: "Proxima Nova";
  font-size: 14px;
  line-height: 17px;
  margin-top: 15px;
}

.form-container-animation {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: auto;
  padding: 0px;
  gap: 15px;
  width: 100%;
  height: 100%;
}

.quiz-hidden {
  overflow: hidden;
}

.custom-option {
  width: 100%;
  height: auto;
  gap: 10px;
  line-height: 25px;
  border: 1px solid white;
  border-radius: 5px;
}
.custom-option textarea {
  padding: 24px;
  border: 1px solid rgb(199, 193, 186);
  margin: auto;
  width: 100%;
}
.custom-option textarea:focus {
  outline: 1px solid rgb(216, 131, 102);
  border: none;
}

.custom-quiz-footer {
  width: 100%;
  font-family: "Proxima Nova";
  font-weight: 600;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 0.15em;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: absolute;
  bottom: 0px;
  justify-content: space-between;
  align-items: center;
  margin-top: 0px;
}
.custom-quiz-footer:hover {
  cursor: pointer;
}

.prev-btn-quiz, .next-btn-quiz, .submit-quiz-btn {
  border: 1px solid black;
  border-radius: 8px;
  padding: 16px 54px;
}
.prev-btn-quiz:focus, .next-btn-quiz:focus, .submit-quiz-btn:focus {
  outline: 0;
}
.prev-btn-quiz:hover, .next-btn-quiz:hover, .submit-quiz-btn:hover {
  background-color: black;
  color: white;
}

.prev-btn-quiz-disabled:hover {
  background-color: white;
  color: black;
}

.checkbox-directions {
  text-transform: none;
}

.submit-quiz-btn {
  display: flex;
  padding: 16px 50px;
  background-color: transparent;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Proxima Nova";
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
}

.custom-option-checkbox {
  width: 47%;
  min-width: 255px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 8px;
}
.custom-option-checkbox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  width: 100%;
  display: none;
  cursor: pointer;
}
.custom-option-checkbox label {
  min-width: 254px;
  overflow: hidden;
  border: 1px solid rgb(238, 236, 230);
  position: relative;
  width: 100%;
  cursor: pointer;
  padding-left: 3px;
  padding: 24px;
}
.custom-option-checkbox label:hover {
  border: 1px solid rgb(216, 131, 102);
}
.custom-option-checkbox label::before {
  content: "";
  background-color: transparent;
  border: 1.5px solid rgb(238, 236, 230);
  border-radius: 4px;
  padding: 9px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 10px;
}
.custom-option-checkbox input:checked + label:before {
  background-color: #D88366;
  border: 1.5px solid #D88366;
}
.custom-option-checkbox input:checked + label {
  border: 1px solid #D88366;
}
.custom-option-checkbox:focus {
  outline: 0;
}

.custom-option-checkbox input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 29px;
  left: 32.5px;
  width: 5px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.custom-option-radio {
  width: 47%;
  min-width: 255px;
  display: flex;
  align-items: baseline;
  flex-direction: row;
  gap: 8px;
}
.custom-option-radio input {
  padding: 0;
  width: 100%;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}
.custom-option-radio label {
  width: 100%;
  padding: 24px;
  position: relative;
  cursor: pointer;
  border: 1px solid rgb(238, 236, 230);
}
.custom-option-radio label::before {
  content: "";
  background-color: transparent;
  border: 1px solid rgb(238, 236, 230);
  border-radius: 100%;
  padding: 9px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 10px;
  margin-top: -1px;
}
.custom-option-radio label:hover {
  border: 1px solid rgb(216, 131, 102);
}
.custom-option-radio input:checked + label {
  border: 1px solid #D88366;
}
.custom-option-radio:focus {
  outline: 0;
}

.custom-option-radio input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 29.6px;
  left: 27px;
  width: 14px;
  height: 14px;
  border-radius: 100%;
  background-color: #D88366;
}

.quiz-brochure-checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.quiz-brochure-checkbox input {
  padding: 0;
  transform: scale(1.5);
  height: initial;
  width: initial;
  margin-bottom: 0;
  width: 100%;
  display: none;
  cursor: pointer;
}
.quiz-brochure-checkbox label {
  position: relative;
  cursor: pointer;
}
.quiz-brochure-checkbox label::before {
  content: "";
  background-color: transparent;
  border: 1.8px solid rgb(199, 193, 186);
  border-radius: 3px;
  padding: 7px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 10px;
}

.quiz-brochure-checkbox input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 4px;
  left: 7.5px;
  width: 4.5px;
  height: 9px;
  border: solid rgb(199, 193, 186);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.other-input {
  display: block;
  border: 1px solid rgb(238, 236, 230);
  padding: 24px;
  display: block;
  width: 100%;
  height: 75px;
}

.quiz-contact-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin: 0px auto;
  gap: 15px;
}

.quiz-full-name-div, .quiz-phone-div, .quiz-email-div, .quiz-date-picker-div, .quiz-about-you-div, .budget-div {
  display: flex;
  flex-direction: column;
}
.quiz-full-name-div input[type=text], .quiz-full-name-div input[type=tel], .quiz-full-name-div input[type=email], .quiz-full-name-div input[type=number], .quiz-full-name-div textarea, .quiz-phone-div input[type=text], .quiz-phone-div input[type=tel], .quiz-phone-div input[type=email], .quiz-phone-div input[type=number], .quiz-phone-div textarea, .quiz-email-div input[type=text], .quiz-email-div input[type=tel], .quiz-email-div input[type=email], .quiz-email-div input[type=number], .quiz-email-div textarea, .quiz-date-picker-div input[type=text], .quiz-date-picker-div input[type=tel], .quiz-date-picker-div input[type=email], .quiz-date-picker-div input[type=number], .quiz-date-picker-div textarea, .quiz-about-you-div input[type=text], .quiz-about-you-div input[type=tel], .quiz-about-you-div input[type=email], .quiz-about-you-div input[type=number], .quiz-about-you-div textarea, .budget-div input[type=text], .budget-div input[type=tel], .budget-div input[type=email], .budget-div input[type=number], .budget-div textarea {
  padding: 18px;
  border: 1px solid rgb(199, 193, 186);
  border-radius: 8px;
  min-width: none;
}
.quiz-full-name-div input[type=text]:focus, .quiz-full-name-div input[type=tel]:focus, .quiz-full-name-div input[type=date]:focus, .quiz-full-name-div input[type=email]:focus, .quiz-full-name-div input[type=number]:focus, .quiz-full-name-div textarea:focus, .quiz-phone-div input[type=text]:focus, .quiz-phone-div input[type=tel]:focus, .quiz-phone-div input[type=date]:focus, .quiz-phone-div input[type=email]:focus, .quiz-phone-div input[type=number]:focus, .quiz-phone-div textarea:focus, .quiz-email-div input[type=text]:focus, .quiz-email-div input[type=tel]:focus, .quiz-email-div input[type=date]:focus, .quiz-email-div input[type=email]:focus, .quiz-email-div input[type=number]:focus, .quiz-email-div textarea:focus, .quiz-date-picker-div input[type=text]:focus, .quiz-date-picker-div input[type=tel]:focus, .quiz-date-picker-div input[type=date]:focus, .quiz-date-picker-div input[type=email]:focus, .quiz-date-picker-div input[type=number]:focus, .quiz-date-picker-div textarea:focus, .quiz-about-you-div input[type=text]:focus, .quiz-about-you-div input[type=tel]:focus, .quiz-about-you-div input[type=date]:focus, .quiz-about-you-div input[type=email]:focus, .quiz-about-you-div input[type=number]:focus, .quiz-about-you-div textarea:focus, .budget-div input[type=text]:focus, .budget-div input[type=tel]:focus, .budget-div input[type=date]:focus, .budget-div input[type=email]:focus, .budget-div input[type=number]:focus, .budget-div textarea:focus {
  outline: rgb(199, 193, 186);
}
.quiz-full-name-div input[type=text]:active, .quiz-full-name-div input[type=tel]:active, .quiz-full-name-div input[type=date]:active, .quiz-full-name-div input[type=email]:active, .quiz-full-name-div input[type=number]:active, .quiz-full-name-div textarea:active, .quiz-phone-div input[type=text]:active, .quiz-phone-div input[type=tel]:active, .quiz-phone-div input[type=date]:active, .quiz-phone-div input[type=email]:active, .quiz-phone-div input[type=number]:active, .quiz-phone-div textarea:active, .quiz-email-div input[type=text]:active, .quiz-email-div input[type=tel]:active, .quiz-email-div input[type=date]:active, .quiz-email-div input[type=email]:active, .quiz-email-div input[type=number]:active, .quiz-email-div textarea:active, .quiz-date-picker-div input[type=text]:active, .quiz-date-picker-div input[type=tel]:active, .quiz-date-picker-div input[type=date]:active, .quiz-date-picker-div input[type=email]:active, .quiz-date-picker-div input[type=number]:active, .quiz-date-picker-div textarea:active, .quiz-about-you-div input[type=text]:active, .quiz-about-you-div input[type=tel]:active, .quiz-about-you-div input[type=date]:active, .quiz-about-you-div input[type=email]:active, .quiz-about-you-div input[type=number]:active, .quiz-about-you-div textarea:active, .budget-div input[type=text]:active, .budget-div input[type=tel]:active, .budget-div input[type=date]:active, .budget-div input[type=email]:active, .budget-div input[type=number]:active, .budget-div textarea:active {
  outline: rgb(238, 236, 230);
}

.quiz-email-div, .quiz-phone-div, .quiz-date-picker-div, .quiz-time-picker-div, .quiz-date-picker, .referred-by-div, .budget-div {
  width: 100%;
}
.quiz-email-div label, .quiz-phone-div label, .quiz-date-picker-div label, .quiz-time-picker-div label, .quiz-date-picker label, .referred-by-div label, .budget-div label {
  margin-bottom: 5px;
}

.quiz-about-you-div label, .quiz-full-name-div label {
  margin-bottom: 5px;
}

.date-time-picker-div, .email-phone-div {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 15px;
}

.quiz-date-picker-div, .quiz-time-picker-div {
  position: relative;
}
.quiz-date-picker-div input[type=text], .quiz-time-picker-div input[type=text] {
  padding: 18px 18px 18px 43px;
}

.referred-by-div {
  position: relative;
}

.react-datepicker__month-container {
  z-index: 999;
}

.react-select__control, .react-select-referred__control {
  border: 1px solid rgb(199, 193, 186);
  border-color: rgb(199, 193, 186);
  border-radius: 8px;
  overflow-x: scroll;
  height: 57.13px;
}
.react-select__control ::-webkit-scrollbar, .react-select-referred__control ::-webkit-scrollbar {
  display: none;
}
.react-select__control .react-select__placeholder, .react-select-referred__control .react-select__placeholder {
  color: rgb(199, 193, 186) !important;
  padding-left: 24px;
}
.react-select__control .react-select-referred__placeholder, .react-select-referred__control .react-select-referred__placeholder {
  color: rgb(199, 193, 186) !important;
}
.react-select__control .react-select__indicator-separator, .react-select__control .react-select__indicators, .react-select__control .react-select-referred__indicator-separator, .react-select__control .react-select-referred__indicators, .react-select-referred__control .react-select__indicator-separator, .react-select-referred__control .react-select__indicators, .react-select-referred__control .react-select-referred__indicator-separator, .react-select-referred__control .react-select-referred__indicators {
  display: none;
}

.react-select__multi-value:first-of-type {
  margin-left: 25px;
}

.react-select__control--is-focused, .react-select__control--menu-is-open, .react-select-referred__control--is-focused, .react-select-referred__control--menu-is-open {
  border: 1px solid rgb(199, 193, 186) !important;
  box-shadow: none !important;
}

.quiz-calendar-icon, .quiz-clock-icon {
  position: absolute;
  bottom: 19px;
  left: 18px;
  z-index: 1;
}

.date-caret-icon, .time-caret-icon {
  position: absolute;
  bottom: 20px;
  right: 18px;
  z-index: 1;
}

.quiz-contact-alerts {
  font-size: 14px;
  margin-bottom: 10px;
}

@media screen and (max-width: 1048px) {
  .date-time-picker-div, .email-phone-div {
    flex-direction: column;
  }
  .repeat-quiz-btn, .get-brochure {
    width: 80%;
  }
  .last-slide-footer {
    margin-top: 20%;
  }
}
@media screen and (max-width: 760px) {
  .custom-quiz-footer {
    font-size: 12px;
    letter-spacing: 0.05em;
  }
  .custom-quiz-footer:hover {
    cursor: pointer;
  }
  .prev-btn-quiz, .next-btn-quiz, .submit-quiz-btn {
    padding: 12px 28px;
  }
  .form-container-animation {
    font-size: 14px;
    padding: 0px;
    gap: 0px;
  }
  .custom-option-checkbox {
    min-width: 222px;
  }
  .custom-option-checkbox label {
    min-width: 222px;
    padding-left: 3px;
    padding: 10px;
  }
  .custom-option-checkbox label::before {
    content: "";
    padding: 7px;
    margin-right: 10px;
  }
  .custom-option-checkbox input:checked + label:after {
    top: 16.5px;
    left: 15.5px;
  }
  .custom-option-radio {
    min-width: 222px;
  }
  .custom-option-radio label {
    min-width: 222px;
    padding-left: 3px;
    padding: 10px;
  }
  .custom-option-radio label::before {
    content: "";
    padding: 7px;
    margin-right: 10px;
  }
  .custom-option-radio input:checked + label:after {
    top: 16.8px;
    left: 12.4px;
    width: 11px;
    height: 11px;
  }
  .custom-quiz-question, .finish-quiz-heading {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .custom-quiz-question h3, .finish-quiz-heading h3 {
    line-height: 25px;
    letter-spacing: 0.1px;
    margin-top: 20px;
    margin-bottom: 0px;
  }
  .quiz-container {
    border: 30px solid white;
  }
  .quiz-contact-page {
    min-width: 230px;
    width: 100%;
  }
  .repeat-quiz-btn, .get-brochure {
    width: 48%;
  }
}
@media screen and (max-width: 1324px) and (min-width: 875px) {
  .custom-option-checkbox {
    width: 100%;
  }
  .custom-option-radio {
    width: 100%;
  }
  .quiz-container {
    border: 30px solid white;
  }
  .custom-quiz-question, .finish-quiz-heading {
    font-size: 24px;
  }
  .custom-quiz-question h3, .finish-quiz-heading h3 {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 568px) {
  .custom-option-checkbox {
    width: 100%;
  }
  .custom-option-radio {
    width: 100%;
  }
  .repeat-quiz-btn, .get-brochure {
    width: 80%;
    min-width: 200px;
  }
}
@media screen and (max-width: 955px) {
  .custom-quiz-footer {
    font-size: 14px;
    letter-spacing: 0.05em;
  }
  .custom-quiz-footer:hover {
    cursor: pointer;
  }
  .prev-btn-quiz, .next-btn-quiz, .submit-quiz-btn {
    padding: 14px 36px;
    font-size: 14px;
  }
}
.results-hero {
  background-color: rgb(255, 255, 255);
  padding: 25px;
  display: flex;
  align-items: flex-start;
  border: none;
}

.results-title1 {
  font-family: "Proxima Nova";
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  color: rgb(0, 0, 0);
  margin-bottom: 0px;
}

.results-title2 {
  font-family: "Playfair Display";
  font-size: 34px;
  font-weight: 400;
  line-height: normal;
  color: rgb(0, 0, 0);
  margin: 15px 0px;
}

.results-blurb {
  font-family: "Proxima Nova";
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  color: rgb(0, 0, 0);
  align-self: stretch;
  margin-bottom: 15px;
}

.results-read-btn {
  border-radius: 8px;
  padding: 13px 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(0, 0, 0);
  max-width: 200px;
}
.results-read-btn:focus {
  color: white;
  background-color: black;
}
.results-read-btn:hover {
  color: white;
  background-color: black;
}

.results-btn-text {
  font-family: "Proxima Nova";
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  color: rgb(0, 0, 0);
  text-transform: uppercase;
  margin-right: 5px;
  letter-spacing: 1.25px;
  color: black;
}
.results-btn-text:focus {
  color: white;
  background-color: black;
}
.results-btn-text:hover {
  color: white;
  background-color: black;
}

.results-img {
  width: 100%;
  height: auto;
  min-width: 200px;
  flex: 1;
  border-radius: 8px;
  -o-object-fit: cover;
     object-fit: cover;
}

@media screen and (min-width: 760px) {
  .results-hero {
    padding: 100px;
  }
  .results-div {
    margin-right: 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex: 1;
    align-self: stretch;
  }
  .results-header {
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .results-title1 {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 15px;
  }
  .results-title2 {
    font-size: 60px;
    font-weight: 400;
  }
  .results-blurb {
    font-size: 16px;
    margin-bottom: 50px;
  }
  .results-read-btn {
    padding: 13px 31px;
  }
  .results-btn-text {
    font-size: 14px;
    margin-right: 5px;
    letter-spacing: 1.25px;
  }
  .frame {
    padding: 3.75px;
    width: 7.5px;
    height: 7.5px;
    position: relative;
  }
  .vector {
    width: 100%;
    height: 100%;
    position: relative;
  }
  .vectorTwo {
    width: 40.6%;
    height: 40.6%;
    position: absolute;
    left: 5.16px;
    top: 3.75px;
  }
  .results-img {
    margin: auto 0px;
    width: 50%;
    height: auto;
    max-width: 900px;
    min-width: 300px;
  }
}
@media screen and (max-width: 1040px) and (min-width: 760px) {
  .results-hero {
    padding: 50px;
  }
  .results-img {
    margin: auto 0px;
  }
  .results-blurb {
    margin: 20px 0px;
  }
  .results-header {
    margin: 0px;
  }
  .results-title1 {
    margin-bottom: 0px;
  }
  .results-title2 {
    margin: 0px;
  }
}
.short-form-container {
  width: 100vw;
  position: relative;
  padding-bottom: 262px;
}

.short-form {
  width: 80%;
  margin: auto;
  border-radius: 16px;
  background-color: rgb(238, 236, 230);
  position: absolute;
  bottom: 10%;
  left: 8.5%;
  padding: 50px;
  display: flex;
  flex-direction: column;
}
.short-form .success-msg-text {
  width: -moz-fit-content;
  width: fit-content;
}
.short-form .success-msg-text:last-of-type {
  margin-bottom: 0px;
}

.short-form-body {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.short-form-header {
  text-align: center;
}

.short-form-header-text {
  font-family: "Playfair Display";
  font-size: 48px;
  padding-bottom: 15px;
}

.short-form-header-sub {
  font-family: "Proxima Nova";
  font-size: 14px;
  margin-bottom: 50px;
}

.short-form-input {
  align-self: stretch;
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-family: "Proxima Nova";
}
.short-form-input input[type=text], .short-form-input input[type=tel] {
  padding: 18px;
  margin-bottom: 0px;
  background: #FFFFFF;
  border: none;
  border-radius: 8px;
  width: 100%;
}
.short-form-input input[type=text]:focus, .short-form-input input[type=tel]:focus {
  outline: rgb(199, 193, 186);
}
.short-form-input input[type=text]:active, .short-form-input input[type=tel]:active {
  outline: rgb(238, 236, 230);
}

input::-moz-placeholder {
  color: rgb(199, 193, 186);
  font-family: "Proxima Nova";
}

input::placeholder {
  color: rgb(199, 193, 186);
  font-family: "Proxima Nova";
}

.submit-short-form-btn {
  padding: 16px 54px;
  gap: 10px;
  width: 281px;
  height: auto;
  margin: 20px 0px 0px 0px;
  background: #D88366;
  border-radius: 8px;
  align-self: stretch;
  border: none;
}

.submit-short-form-btn-txt {
  padding: 0;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: #FFFFFF;
  flex: none;
  flex-grow: 0;
}

@media screen and (max-width: 996px) {
  .submit-short-form-btn {
    width: -moz-fit-content;
    width: fit-content;
    padding: 16px 20px;
    gap: 0px;
  }
  .short-form {
    padding: 25px;
    width: 90%;
    left: 5%;
  }
  .short-form .success-msg-header {
    font-size: 34px;
  }
  .short-form .success-msg-text:last-of-type {
    margin-bottom: 0px;
  }
}
@media screen and (max-width: 760px) {
  .short-form-container {
    min-height: 80vh;
  }
  .short-form {
    padding: 25px;
    bottom: 10%;
  }
  .short-form-body {
    flex-direction: column;
    gap: 0px;
  }
  .short-form-input {
    width: 100%;
    font-size: 14px;
    margin-bottom: 15px;
  }
  input::-moz-placeholder {
    color: rgb(199, 193, 186);
    font-size: 14px;
  }
  input::placeholder {
    color: rgb(199, 193, 186);
    font-size: 14px;
  }
  .submit-short-form-btn {
    margin: auto;
    width: 100%;
    min-width: 150px;
    max-width: 400px;
    margin-top: 10px;
  }
  .short-form-header-text {
    font-size: 34px;
    margin-bottom: 10px;
  }
  .short-form-header-sub {
    margin-bottom: 25px;
  }
}
button {
  text-decoration: none !important;
}
button:focus {
  outline: 0 !important;
  border: 1px solid rgb(238, 236, 230);
}
button:active {
  border: 1px solid rgb(238, 236, 230);
}
button:hover {
  cursor: pointer;
}

.request-info-btn {
  text-decoration: none !important;
}/*# sourceMappingURL=styles.css.map */