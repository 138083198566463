
.testimonial-block {
  padding: 50px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.testimonial-header {
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.testimonial-header-sub {
  font-family: "Proxima Nova";
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  color: $terracotta;
  text-transform: uppercase;
  margin-bottom: 5px;
  letter-spacing: 1.6px;
}
.testimonial-header-text {
  font-family: "Playfair Display";
  font-size: 34px;
  font-weight: 400;
  line-height: normal;
  color: $black;
}
.testimonial-card {
  background-color: rgba(65, 88, 102, 1);
  margin-bottom: 25px;
  border-radius: 8px;
  display: flex;
  margin:auto;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
 
}

.testimonial-video-div{
  height: 100%;
  width: 100%;
  position: relative;
 }
 #Capa_1{
  position: absolute;
  top:40%;
  left:45%;
  width: 12%;
  height: auto;
}
.testimonial-img {
  width:100%;
  height: auto;
  object-fit: cover;
  align-self: stretch;
  border-radius: 8px 8px 0px 0px;
}
.testimonial-card-body {
  padding: 6vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width:100%;
  align-self: stretch;
}

.testimonial-stars {
  width: 70px;
  height: 10px;
  margin-bottom: 25px;
}
.testimonial-card-quote {
  font-family: "Playfair Display";
  font-size: 20px;
  font-weight: 400;
  width: 100%;
  line-height: normal;
  color: $white;
  align-self: stretch;
  margin-bottom: 25px;
}
.testimonial-client-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}
.testimonial-client-name {
  font-family: "Proxima Nova";
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  color: $white;
  margin-bottom: 5px;
}
.testimonial-client-date {
  font-family: "Proxima Nova";
  font-size: 10px;
  font-weight: 400;
  line-height: normal;
  color: $white;
}
.testimonial-page-dots {
  margin:25px auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.rectangle654 {
  width: 8px;
  height: 8px;
  margin-right: 8px;
  border-radius: 20px;
}
.rectangle657 {
  width: 8px;
  height: 8px;
  border-radius: 20px;
  &:not(:last-of-type) {
    margin-right: 8px;
  }
}
.testimonial-buttons {
  margin-bottom: 50px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 340px;
}
.left-btn {
  border-radius: 50px;
  padding: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  &:not(:last-of-type) {
    margin-right: 15px;
  }
  &:hover{
    cursor: pointer;
  }
}
.left-arrow, .right-arrow{
  position: relative;
  &:hover{
    cursor: pointer;
  }
}


.request-info {
  font-family: "Proxima Nova";
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  color: $white;
  text-transform: uppercase;
  letter-spacing: 2.7px;
}



 @media screen and (min-width:897px) {
  .testimonial-block1 {
    background-color: $white;
    padding: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .testimonial-header1 {
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
  }
  .testimonial-header-sub1 {
    font-family: "Proxima Nova";
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
    color: $terracotta;
    text-transform: uppercase;
    margin-bottom: 15px;
    letter-spacing: 3px;
  }
  .testimonial-header-group1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-self: stretch;
  }
  .testimonial-header-text1 {
    font-family: "Playfair Display";
    font-size: 60px;
    font-weight: 400;
    line-height: normal;
    color: $black;
  }
  .testimonial-body1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width:100%;
  }
 
  .testimonial-card1 {
    background-color: rgba(65, 88, 102, 1);
    margin-bottom: 18px;
    border-radius: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    max-width: 2000px;
    height: auto;
    min-height: 250px;
  }
  .testimonial-card-body1 {
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: center;
    width:75%;
  }
  .testimonial-stars1 {
    width: 115px;
    height: 15px;
    margin-bottom: 25px;
  }
  
  .testimonial-card-quote1 {
    font-family: "Playfair Display";
    font-size: 34px;
    font-weight: 400;
    width: 100%;
    line-height: normal;
    color: $white;
    align-self: stretch;
    margin-bottom: 25px;
    margin-bottom: 20px;
  }
  .testimonial-client-info1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
  }
  .testimonial-client-name1 {
    font-family: "Proxima Nova";
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    color: $white;
    margin-bottom: 5px;
  }
  .testimonial-client-date1 {
    font-family: "Proxima Nova";
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    color: $white;
  }
  .testimonial-card-img1 {
    width:100%;
    height: 100%;
    // max-height: 500px;
    min-height: 420px;
    object-fit:cover;
    border-radius: 0px 16px 16px 0px;
    align-self: center;
    position:static;
  

  }
  .testimonial-video-div{
   height: 100%;
   width: 100%;
   position: relative;
  
  }

#Capa_1{
  position: absolute;
  top:35%;
  left:43%;
  height: 90px;
  width: auto;
}
  
  .testimonial-page-dots1 {
    margin-bottom: 50px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  .rectangle6541 {
    width: 8px;
    height: 8px;
    margin-right: 8px;
    border-radius: 20px;
  }
  .rectangle6571 {
    width: 8px;
    height: 8px;
    border-radius: 20px;
    &:not(:last-of-type) {
      margin-right: 8px;
    }
  }
  .testimonial-buttons1 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    width: 100%;
  }
  .left-btn1 {
    border-radius: 50px;
    padding: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
    &:not(:last-of-type) {
      margin-right: 25px;
    }
    &:hover{
      cursor: pointer;
    }
  }
  .left-arrow1, .right-arrow1 {
    position: relative;
  }
 
  


 }
 @media screen and (max-width:1255px) and (min-width:897px) {
  .testimonial-card-quote1{
    font-size: 34px;
  }
  .testimonial-card-body1{
    padding:50px;
  }
  .testimonial-card-img1 {
    min-width: 300px;

  }
  .testimonial-block1{
    padding:100px 50px;
  }
  .testimonial-header-text1{
    font-size: 42px;
  }
  .request-info{
    width:250px;
  }
 }
//  @media screen and (max-width:807px) and (min-width:897px) {
//   .testimonialCardQuote1{
//     font-size: 16px;
//   }
//   .testimonialHeaderText1{
//     font-size: 39px;
//   }
//  }

