.footer-container {

    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width:100vw;
  }
 
  .corso-logo {
    width: 120px;
    height: 19.4px;
    margin-bottom:20px;
  }
  .footer-header-div{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width:100%;
    gap:10px;
    margin:0px auto;
    // margin-bottom:20px;
  }
  .footer-div{
    width:100%;
  }
  .social-media-container {
    display: flex;
    align-items: flex-start;
    margin-bottom:20px;
  }
  .facebook-icon {
    margin-right: 14px;
    border-radius: 8px;
    padding: 12px;
    display: flex;
    align-self: center;
  
  }
  .facebook {
    flex: 1;
    align-self: stretch;
    object-fit: cover;
  }
  .instagram-icon {
    border-radius: 8px;
    padding: 12px;
    display: flex;
    align-self: center;
  }
  .instagram {
    width: 22.5px;
    height: 22.5px;
  }
  .footer-line {
    height: 1px;
    background-color: $grey-1;
    margin-bottom: 25px;
    align-self: stretch;
  }
  .footer-column {
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .footer-title {
    font-family: "Playfair Display";
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    color: $black;
    margin-bottom: 25px;
    white-space: nowrap;
  }
  .footer-description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .footer-address, .footer-link {
    font-family: "Proxima Nova";
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    color: $black;
    text-decoration: none !important;
    margin-bottom: 25px;
    &:hover{
      color:gray;
      cursor: pointer;
    }
  }
  .footer-phone-number {
    font-family: "Proxima Nova";
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    text-decoration: none !important;
    color: $black;
  
    margin-bottom: 25px;
    &:hover{
      color:gray;
      cursor: pointer;
    }
  }
  a.footer-email {
    font-family: "Proxima Nova";
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    text-decoration: none !important;
    color: $black;
    
    text-transform: lowercase;
    text-decoration: underline;
    &:hover{
      color:gray;
      cursor: pointer;
    }
  }
 
  .brochure {
    font-family: "Proxima Nova";
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    color: $black;
    text-decoration:underline;
    &:hover{
      cursor: pointer;
      color:gray;
    }
  }
  .copyright {
    padding: 14px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    border-top: 1px solid $grey-1;
    a{
      color:$grey-1;
    }
  }
  .copyright-description {
    font-family: "Proxima Nova";
    font-size: 10px;
    font-weight: 400;
    line-height: normal;
    color: $grey-1;
    align-self: stretch;
    text-align: center;
    text-decoration: underline;
  }
  

  @media screen and (min-width:870px){
    .footer-container{
        width:100%;
    }
    .footer-columns-div{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        gap:20px;
        
    }
    .footer-column{
        width: 100%;
        max-width: 600px;
    }
    .footer-div{
        display: flex;
        flex-direction: row;
       gap:30px;
            justify-content: space-between;
            align-self: stretch;
        
    }
    .footer-header-div{
        align-self: flex-start; 
        flex-direction: column;
        width:127.87px;
        margin:0px;
        justify-content: normal;
        gap: 0px;
      
    }
    .social-media-container{
        margin: 0px auto;
    }
  }

  @media screen and (max-width:342px){
    .footer-header-div{
      flex-direction: column;
      align-items: center;
    }
  }