.social-handle-container{
    padding:100px 50px;
    width:100%;
}
.social-handle{
    font-family: 'Playfair Display';
    font-style: italic;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    margin-bottom: 50px;
    
   
}
.social-divider{
    width:100vw;
    border:1px solid $grey-2;
    margin-top: 50px;
}
.social-handle-text{
    color:black;
    text-decoration: none;
    &:hover{
        color:$terracotta;
    }
}
.social-image-banner{
    display:flex;
    flex-direction: row;
    margin:auto;
    width:100%;
    justify-content: center;
}
.ig-link{
    width:100%;
    max-width: 300px;
    height: auto;
}
.socialHandleImg{
    width:100%;
    max-width: 300px;
    height: auto;
    object-fit: cover;
}
.view-ig-btn {
    border-radius: 8px;
    padding: 13px 0px;
    width:100%;
    max-width: 250px;
    min-width: 152px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    justify-content: center;
    border: 1px solid $black;
    margin:50px auto 0px auto;
    color:white;

    &:active{
        background-color: black;
        color: white;
    }
  }
  .view-ig-btn-text{
    font-family: "Proxima Nova";
    font-size: 15px;
    font-weight: 600;
    color: $black;
    text-transform: uppercase;
    color: black;
    &:active{
       color: white;
    }
  }
 @media screen and (max-width:760px){
    .socialHandleImg{
        width:100%;
        max-width: 700px;
        height: auto;
        object-fit: cover;
    }
 }