
// .results-card-mobile{
//   width:
//  }

.results-hero {
  background-color: $white;
  padding: 25px;
  display: flex;
  align-items: flex-start;
  border:none;
}


.results-title1 {
  font-family: "Proxima Nova";
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  color: $black;
  margin-bottom: 0px;
}
.results-title2 {
  font-family: "Playfair Display";
  font-size: 34px;
  font-weight: 400;
  line-height: normal;
  color: $black;
  margin:15px 0px;
}
.results-blurb{
  font-family: "Proxima Nova";
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  color: $black;
  align-self: stretch;
  margin-bottom: 15px;
}
.results-read-btn {
  border-radius: 8px;
  padding: 13px 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $black;
  max-width:200px;
  &:focus{
      color: white;
      background-color: black;
  }
  &:hover{
      color: white;
      background-color: black;
  }
}
.results-btn-text {
  font-family: "Proxima Nova";
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  color: $black;
  text-transform: uppercase;
  margin-right: 5px;
  letter-spacing: 1.25px;
  color:black;
  &:focus{
      color: white;
      background-color: black;
  }
  &:hover{
      color: white;
      background-color: black;
  }
}


.results-img{
  width:100%;
  height:auto;
  min-width:200px;
  flex: 1;
  border-radius: 8px;
  object-fit: cover;
}


@media screen and (min-width:760px){
  .results-hero {
    padding: 100px;
  }
  .results-div{
    margin-right: 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex: 1;
    align-self: stretch;
  }
  .results-header {
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .results-title1 {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 15px;
  }
  .results-title2 {
    font-size: 60px;
    font-weight: 400;
  }
  .results-blurb{
    font-size: 16px;
    margin-bottom: 50px;
  }
  .results-read-btn {
    padding: 13px 31px;
  }
  .results-btn-text {
    font-size: 14px;
    margin-right: 5px;
    letter-spacing: 1.25px;
  }
  .frame {
    padding: 3.75px;
    width: 7.5px;
    height: 7.5px;
    position: relative;
  }
  .vector {
    width: 100%;
    height: 100%;
    position: relative;
  }
  .vectorTwo {
    width: 40.6%;
    height: 40.6%;
    position: absolute;
    left: 5.16px;
    top: 3.75px;
  }
  .results-img{
    margin:auto 0px;
    width:50%;
    height:auto;
    max-width: 900px;
    min-width:300px;
   
  }

  
}

@media screen and (max-width:1040px) and (min-width:760px){
  .results-hero{
    padding:50px;
  }
  .results-img{
    margin:auto 0px;
  }
  .results-blurb{
    margin:20px 0px;
  }
  .results-header{
    margin:0px;
  }
  .results-title1{
    margin-bottom: 0px;
  }
  .results-title2{
    margin: 0px;
  }
}