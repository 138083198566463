





.mobile-banner-div{
    display: flex;
  flex-direction: column;
   padding: 0px;
  z-index: -1;
  flex: none;
  order: 3;
  align-self: stretch;
  height:100px;
  width:100%;
  background-image: url(../landing_page/imgs/mobilePattern2.png);
  flex-grow: 0;
  }
  .banner-pattern{
    z-index: 999;
    height: 82px;
    width: 100vw;
    object-fit: cover;
    background-repeat: repeat-x;
   
  }
  
 