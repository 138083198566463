.short-form-container{
 width:100vw;
 position: relative;
padding-bottom: 262px;

}

.short-form{
    width:80%;
    margin:auto;
    border-radius: 16px;
    background-color: $grey-2;
    position: absolute;
    bottom:10%;
    left:8.5%;
    padding: 50px;
    display: flex;
    flex-direction: column;
    .success-msg-text{
      
        width: fit-content;
        &:last-of-type{
        margin-bottom: 0px;
    }}
  
    
}
.short-form-body{
    display: flex;
    flex-direction: row;
    gap: 15px;

}
.short-form-header{
    text-align: center;
}
.short-form-header-text{
    font-family:  "Playfair Display";
    font-size: 48px;
    padding-bottom: 15px;
   
}

.short-form-header-sub{
    font-family:   "Proxima Nova";
    font-size: 14px;
    margin-bottom: 50px;
}

.short-form-input{
    align-self: stretch;
    width:40%;
    display: flex;
    flex-direction: column;
    gap:5px;
    font-family:   "Proxima Nova";
    input[type=text],input[type=tel] {
        padding:18px;
        margin-bottom: 0px;
        background: #FFFFFF;
        border: none;
      
     
        border-radius: 8px;
        width:100%;
        &:focus{
          outline:$grey-1;
        }
        &:active{
          outline:$grey-2;
        }
      }
}
input::placeholder{
    color:$grey-1;
    font-family:   "Proxima Nova";
   }


   .submit-short-form-btn{
    padding: 16px 54px;
    gap:10px;
    width: 281px;
    height:auto;
    margin:20px 0px 0px 0px;
  
    background: #D88366;
    border-radius: 8px;
    align-self: stretch;
    border: none;
    
  }
  .submit-short-form-btn-txt{
    padding: 0;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: #FFFFFF;
    flex: none;
 
 
    flex-grow: 0;
  }

  @media screen and (max-width:996px){
    .submit-short-form-btn{
        width:fit-content;
        padding: 16px 20px;
        gap:0px;
       
} 
    .short-form{
        padding: 25px;
        width:90%;
        left:5%;
        .success-msg-header{
            font-size: 34px;
        }
        .success-msg-text{
            &:last-of-type{
            margin-bottom: 0px;
        }
    }
    }
  }
  @media screen and (max-width:760px){
    .short-form-container{
        min-height: 80vh;
    }

    .short-form{
        padding: 25px;
        bottom:10%;
    }
    .short-form-body{
        flex-direction: column;
        gap:0px;
        
    }

   
    .short-form-input{
       width:100%;
       font-size: 14px;
       margin-bottom: 15px;
    }
    input::placeholder{
        color:$grey-1;
        font-size: 14px;
       }
    
    .submit-short-form-btn{
            margin: auto;
            width:100%;
            min-width: 150px;
            max-width: 400px;
            margin-top: 10px;
    } 
    .short-form-header-text{
        font-size: 34px;
        margin-bottom: 10px;
    }
    .short-form-header-sub{
        margin-bottom: 25px;
    }
}