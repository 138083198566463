
// a:link{
//   text-decoration: none!important;
// }
.hero-column {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    width: 100%;
    height: auto;
    flex: none;
    gap:50px;
    order: 0;
    flex-grow: 0;
  }
.hero-information {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 50px 25px;
    gap: 40px;
    background: $grey-2;
    flex: none;
   
    align-self: stretch;
    flex-grow: 0;
  }
  .hero-title {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 64px;
  text-align: center;
  color: #000000;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  }

  .contact-specifics {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 15px;

  flex: none;
  align-self: stretch;
  flex-grow: 0;
  }
  .email-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 15px;
    width: 52px;
    height: 52px;
    border: 1px solid #000000;
    border-radius: 8px;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .email-text{
    color:black;
  }
  .email-button2, .phone-button2, .address-button2{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 22px;
    gap: 15px;
    border: 1px solid #000000;
    border-radius: 8px;
    flex: none;
    align-self: stretch;
    flex-grow: 0;
    text-decoration: none !important;
      }
   .phone-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 14px;
    gap: 15px;
    width: 52px;
    height: 52px;
    border: 1px solid #000000;
    border-radius: 8px;
    flex: none;
    order: 1;
    text-decoration: none !important;
    flex-grow: 0;
  }
   .address-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px;
    gap: 15px;
     width: 52px;
    height: 52px;
    border: 1px solid #000000;
    border-radius: 8px;
    flex: none;
    order: 2;
    flex-grow: 0;
    text-decoration: none !important;
  }
   .quiz-modal {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 340px;
    height: auto;
    padding: 25px;
    gap: 25px;
    margin:auto;
    background: #FFFFFF;
    border-radius: 12px;
    
  }
  .quiz-modal-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 15px;
    width: 100%;
    max-width: 340px;
    height: auto;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
  }
  .one-minute-care-quiz {
    font-family: "Proxima Nova";
    font-size: 12px;
    font-weight: 600;
    padding:0px;
    line-height: normal;
    color: $black;
    text-transform: uppercase;
    margin-bottom: 15px;
    letter-spacing: 1.25px;
  }
  .hero-divider {
    height:1px;
    border-top:1px solid $grey-2;
    width: 100%;
   
  }
  .question {
    width: 100%;
    max-width: 340px;
    height: auto;
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: $black;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
  }
  .take-quiz-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 0px;
  gap: 10px;
  width:100%;
  max-width: 290px;
  height: auto;
  background: $terracotta;
  border-radius: 8px;
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
  }
   .take-the-quiz {
    font-family: "Proxima Nova";
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
    color: $white;
    text-transform: uppercase;
    letter-spacing: 2.7px;
  }
  .quiz-card{
    width: 100%;
    max-width: 2000px;
    height: auto;
    border-radius: 8px;
  } 
.quiz-div-mobile{
  width:100%;
  max-width: 700px;
  height:auto;
}

// .quizCard-mobile{
//   width:100%;
//   max-width:400px;
//   height:auto;
// }

// .version-b{
//   display: none;
// }
 @media screen and (min-width:875px) {
  .hero-block{
    background-color: $grey-2;
   
    padding: 100px 73px 100px 73px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 100px;
    height: 100%;
   
    background: $grey-2;
    // flex:none;
    // order: 1;
    // flex-grow: 0;

    }
    .hero-information {
        align-items: flex-start;
        padding:0px;
        background: $grey-2;
        width:41%;
        // align-self: auto;
        height: auto;
        max-height: 100vh;
        min-height: none;
       
     
      }
      .quiz-div{
        width: 55.24%;
        display: flex;
        align-self: stretch;
      }
      
      .hero-title{
        font-size: 78px;
        line-height:normal;
        align-self: stretch;
        width:100%;
        max-width: 900px;
        text-align: left;
      }
      .contact-specifics {
        flex-direction:column;
        justify-content:normal;
        align-self: stretch;
        max-width: 600px;
        }
   
 }

 @media screen and (max-width:1040px) and (min-width:875px) {
  .hero-title{
      font-size: 48px;
      line-height: normal;
  }
  .hero-block{
    padding: 50px;
  }
  }
  @media screen and (max-width:1420px) and (min-width:1040px) {
    .hero-title{
        font-size: 58px;
        line-height: normal;
    }
    .hero-block{
      padding: 50px;
    }
    // .heroColumn{
    //   gap:20px;
    // }
 
    }

    @media screen and (min-width:2200px){
      .hero-information {
        align-items: center;
      }
      .contact-specifics {
        width:100%;
        margin:auto;
        }
   
    }