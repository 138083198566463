.values-banner{
    display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 25px;
  background: #415866;
  flex: none;
  order: 3;
  gap:.6rem;
  align-self: stretch;
  flex-grow: 0;
  }
  .values-banner-text, .values-banner-italics{
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  text-transform: capitalize;
  color: #FFFFFF;
  flex: none;
  order: 0;
  flex-grow: 0;
  }
.values-banner-italics{
font-style: italic;
}
@media screen and (min-width:760px) {
    .values-banner{
        padding:50px;
        gap:.6rem;
    }
    .values-banner-text, .values-banner-italics{
        font-size: 36px;
        line-height: 48px;
    }
}