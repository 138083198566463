// !!!!!  MEDIA QUERIES ON THIS PAGE ARE OPPOSITE OF OTHER PAGES - THE MEDIA QUERY IS FOR MOBILE VIEW
.quiz-container{
  width: 100%;
  max-width: 1200px;
  height: auto;
  border:50px solid white;
  background-color: white;
  border-radius: 8px;

}
.custom-quiz-options{
  height:100%;
  width:100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  position:relative;
}

.finish-quiz-div{
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  width: 100%;
}
.finish-quiz-blurb{
  line-height: 19.5px;
}
.repeat-quiz-btn{
 color:black;
 border: 1px solid black;
 width:48%;
}
.get-brochure{
  color: white;
  background-color: $terracotta;
  width:48%;
}
.repeat-quiz-btn,.get-brochure{
  margin: 0px auto;
  border-radius: 8px;
  text-transform: uppercase;
  font-family: 'Proxima Nova';
  padding-top:16px;
  padding-bottom:16px;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.15em;
  min-width: 190px;
  font-weight: 600;
  text-align: center;
  align-self: baseline;
}
.last-slide-footer{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap:10px;
}
.quiz-options-only{
  // height: 100%;
  font-family: 'Proxima Nova';
  font-weight: 400;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap:15px;
  width:100%;
  margin-bottom: 75px;
}
.custom-quiz-body{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // gap: 50px;
    background-color: white;
    font-family: 'Proxima Nova';
    border-radius: 8px;
    
    // overflow-x: hidden;

}

.quiz-divider{
  width:100%;
  border-top:1px solid $grey-1;
  margin-top:15px;
  position: relative;
  
}
.quiz-contact-us{
  text-decoration: underline !important;
  color:#D88366;
}
.quiz-progress-bar{
  // width:100%;
  border-top:2px solid $terracotta;
  position: absolute;
  top:-1px;
  // left:-88.88%
  left:0px;
  
}
.custom-quiz-header{
    width:100%;
    padding: 0px;
    background-color: white;
    z-index:999;
    h1{
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: .7px;
      text-transform: uppercase;

    }
    .custom-quiz-header-text{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      padding: 0px;
    }
}


.custom-quiz-question,.finish-quiz-heading{
font-size: 24px;
text-transform:capitalize;
font-family: "Playfair Display";
h3{
  line-height: 35px;
  letter-spacing: .2px;
  margin-top: 50px;
  margin-bottom: 35px;
}
p{
  font-family: 'Proxima Nova';
  font-size: 14px;
  line-height: 17px;
  margin-top: 15px;
}
}
.form-container-animation{
  align-self: stretch;
display: flex;
flex-direction: column;
align-items: flex-start;
height: auto;
padding: 0px;
gap: 15px;
width: 100%;
height:100%;


}
.quiz-hidden{
  overflow: hidden;
}

.custom-option{
    width: 100%;
    height: auto;
    // padding: 11px;
    gap: 10px;
    line-height: 25px;
    border:1px solid white;
    border-radius: 5px;
    textarea{
      padding:24px;
      border: 1px solid $grey-1;
      margin:auto;
      width:100%;
      &:focus{
        outline:1px solid $terracotta;
        border: none;
      }
    }
}
.custom-quiz-footer{
    width:100%;
    font-family: 'Proxima Nova';
    font-weight: 600;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 0.15em;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    position: absolute;
    bottom:0px;
    justify-content: space-between;
    align-items: center;
   margin-top: 0px;
  //  padding-top: 40px;
   &:hover{
    cursor: pointer;
   }
}
.prev-btn-quiz, .next-btn-quiz,.submit-quiz-btn{
  border:1px solid black;
  border-radius: 8px;
  padding:16px 54px;
  &:focus{
    outline:0;
  }
  &:hover{
    background-color: black;
    color:white;
  }
}
.prev-btn-quiz-disabled{
  &:hover{
    background-color: white;
    color:black;
  }
}
.checkbox-directions{
  text-transform: none;
}
.submit-quiz-btn{
    display: flex;
    padding:16px 50px;
    background-color: transparent;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Proxima Nova';
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
}
.custom-option-checkbox{
  width:47%;
  min-width: 255px;
  // margin:15px;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    gap: 8px;
    input{
      padding: 0;
      height: initial;
      width: initial;
      margin-bottom: 0;
      width: 100%;
      display: none;
      cursor: pointer;
    }
    label{
      min-width: 254px;
      overflow: hidden;
      border:1px solid $grey-2;
      position: relative;
      width: 100%;
      cursor: pointer;
      padding-left:3px;
      padding:24px;
      &:hover{
        border:1px solid $terracotta;
      }
      &::before{
        content:'';
      // -webkit-appearance: none;
      background-color: transparent;
      border: 1.5px solid $grey-2;
      border-radius: 4px;
      padding: 9px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      margin-right: 10px;
   
      }
    }
    input:checked + label:before{
      background-color: #D88366;
      border: 1.5px solid #D88366;
     }
     input:checked + label{
      border:1px solid #D88366;
     }
    &:focus{
      outline:0;
    }
  
   }
 
  
   .custom-option-checkbox input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 29px;
    left:32.5px;
    width: 5px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  .custom-option-radio{
    // border:1px solid $grey-2;
    width:47%;
    min-width: 255px;
    display: flex;
    align-items: baseline;
    flex-direction: row;
    gap: 8px;
    input{
      padding: 0;
      width: 100%;
      height: initial;
      width: initial;
      margin-bottom: 0;
      display: none;
      cursor: pointer;
    }
    label{
      width: 100%;
      padding:24px;
      position: relative;
      cursor: pointer;
      border: 1px solid $grey-2;
      &::before{
        content:'';
      // -webkit-appearance: none;
      background-color: transparent;
     border:1px solid $grey-2;
      border-radius: 100%;
      padding: 9px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      margin-right: 10px;
      margin-top: -1px;
      }
      &:hover{
        border:1px solid $terracotta;
      }
    }
    input:checked + label{
      border:1px solid #D88366;
     }
    &:focus{
      outline:0;
    }
  
   }

   .custom-option-radio input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 29.6px;
    left: 27px;
    width: 14px;
    height:14px;
    border-radius:100%;
    background-color: #D88366;
  }

  .quiz-brochure-checkbox{
    display: flex;
    flex-direction: row;
    align-items: center;
    
    gap: 8px;
    input{
      padding: 0;
      transform: scale(1.5);
      height: initial;
      width: initial;
      margin-bottom: 0;
      width: 100%;
      display: none;
      
      cursor: pointer;
    }
    label{
      position: relative;
      cursor: pointer;
      &::before{
        content:'';
      // -webkit-appearance: none;
      background-color: transparent;
      border: 1.8px solid $grey-1;
      border-radius: 3px;
      padding: 7px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      margin-right: 10px;
      }
    }
   }
   .quiz-brochure-checkbox input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 4px;
    left: 7.5px;
    width: 4.5px;
    height: 9px;
    border: solid $grey-1;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  .other-input{
    display: block;
    border:1px solid $grey-2;
    padding: 24px;
    display:block;
    width:100%;
    height:75px;

  }
  .quiz-contact-page{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    margin:0px auto;
    gap:15px;
  }
  .quiz-full-name-div, .quiz-phone-div,.quiz-email-div, .quiz-date-picker-div,.quiz-about-you-div, .budget-div{
    display:flex;
    flex-direction: column;
    input[type=text],input[type=tel],input[type=email],input[type=number], textarea {
      padding:18px;
      border:1px solid $grey-1;
      border-radius: 8px;
      min-width: none;
    }
      input[type=text],input[type=tel],input[type=date],input[type=email],input[type=number], textarea {
        &:focus{
          outline:$grey-1;
        }
        &:active{
          outline:$grey-2;
        }
      }
  }
  .quiz-email-div, .quiz-phone-div, .quiz-date-picker-div, .quiz-time-picker-div, .quiz-date-picker, .referred-by-div, .budget-div{
    width:100%;
    label{
      margin-bottom: 5px;
    }
  }
  .quiz-about-you-div, .quiz-full-name-div{
    label{
      margin-bottom: 5px;
    }
  }
  .date-time-picker-div, .email-phone-div{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap:15px;

  }
  .quiz-date-picker-div, .quiz-time-picker-div{
    position: relative;
    input[type=text]{
      padding:18px 18px 18px 43px
    }
  }
  .referred-by-div{
    position: relative;
  }
  .react-datepicker__month-container{
    z-index: 999;
  }
  
  .react-select__control,  .react-select-referred__control{
    border:1px solid $grey-1;
    border-color:$grey-1;
    border-radius: 8px;
    overflow-x: scroll;
    height: 57.13px;
    ::-webkit-scrollbar{
      display: none;
    }
    .react-select__placeholder{
      color:$grey-1 !important; 
      padding-left: 24px;
    }
    .react-select-referred__placeholder{
      color:$grey-1 !important; 
    }
  
    .react-select__indicator-separator,.react-select__indicators,.react-select-referred__indicator-separator,.react-select-referred__indicators{
      display: none;
    }
  }
  .react-select__multi-value:first-of-type{
    margin-left: 25px;

  }
 .react-select__control--is-focused, .react-select__control--menu-is-open,.react-select-referred__control--is-focused, .react-select-referred__control--menu-is-open {
      border: 1px solid $grey-1 !important;
      box-shadow: none !important;
    }
  .quiz-calendar-icon, .quiz-clock-icon{
      position: absolute;
      bottom:19px;
      left:18px;
      z-index: 1;
  }
 .date-caret-icon,.time-caret-icon{
      position: absolute;
      bottom:20px;
      right:18px;
      z-index: 1;
 }
 .quiz-contact-alerts{
  font-size:14px;
  margin-bottom:10px;
 }

// !!!!!  MEDIA QUERIES HERE ARE OPPOSITE OF OTHERS - THE MEDIA QUERY IS FOR MOBILE VIEW
@media screen and (max-width:1048px){
  .date-time-picker-div, .email-phone-div{
    flex-direction: column;
  }
  .repeat-quiz-btn,.get-brochure{
    width:80%;
  }
  .last-slide-footer{
    margin-top: 20%;
  }
}
@media screen and (max-width:760px){
      .custom-quiz-footer{
        font-size: 12px;
        letter-spacing: 0.05em;
      &:hover{
        cursor: pointer;
      }
    }
    .prev-btn-quiz, .next-btn-quiz,.submit-quiz-btn{
      padding:12px 28px;
    }

    // .submit-quiz-btn{
    //     padding:12px 28px;
    //     font-size: 12px;
    //     letter-spacing: 0.05em;
    // }
    .form-container-animation{
    font-size: 14px;
    padding: 0px;
    gap: 0px;
    }
    .custom-option-checkbox{
      min-width: 222px;
        label{
          min-width: 222px;
          padding-left:3px;
          padding:10px;
          &::before{
            content:'';
          padding: 7px;
          margin-right: 10px;
          }
        }
      }
      .custom-option-checkbox input:checked + label:after {
        top: 16.5px;
        left:15.5px;
      }

      .custom-option-radio{
        min-width: 222px;
          label{
            min-width: 222px;
            padding-left:3px;
            padding:10px;
            &::before{
              content:'';
            padding: 7px;
            margin-right: 10px;
            }
          }
          
        }
        .custom-option-radio input:checked + label:after {
          top: 16.8px;
          left: 12.4px;
          width:11px;
          height:11px;
          
        }
  
        
      .custom-quiz-question,.finish-quiz-heading{
        font-size: 18px;
        margin-bottom: 20px;
        h3{
          line-height: 25px;
          letter-spacing: .1px;
          margin-top: 20px;
          margin-bottom: 0px;
        }
        
        }
      .quiz-container{
        border:30px solid white;
      }
    .quiz-contact-page{
      min-width: 230px;
      width: 100%;
    }
    .repeat-quiz-btn,.get-brochure{
      width:48%;
    }
    
}

@media screen and (max-width:1324px) and (min-width:875px){
  .custom-option-checkbox{
    width:100%;
  }
  .custom-option-radio{
    width:100%;
  }
  .quiz-container{
    border:30px solid white;
  }
  .custom-quiz-question,.finish-quiz-heading{
    font-size: 24px;
    h3{
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }

}
@media screen and (max-width:568px){
  .custom-option-checkbox{
    width:100%;
  }
  .custom-option-radio{
    width:100%;
  }
  .repeat-quiz-btn,.get-brochure{
    width:80%;
    min-width:200px;
  }
}

@media screen and (max-width:955px){
.custom-quiz-footer{
  font-size: 14px;
  letter-spacing: 0.05em;
&:hover{
  cursor: pointer;
}
}
.prev-btn-quiz, .next-btn-quiz,.submit-quiz-btn{
padding:14px 36px;
font-size: 14px;
}}