.residences{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 25px;
} 
.residences-body{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: normal;
  width:100%;
  min-width: 280px;
  height: auto;
  padding: 0px;
  gap:35px;
  margin: 50px 0px;

}
.residences-sub{
  width: 110px;
  font-family: "Proxima Nova";
  font-size: 16px;
  font-weight: 600px;
  line-height: normal;
  color: $terracotta;
  text-transform: uppercase;
  margin-bottom: 15px;
  letter-spacing: .1em;
}
.residences-title{
  font-family: "Playfair Display";
  font-size: 34px;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  color: $black;

}
.residences-body-text{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width:80%;
  min-width: 315px;
  height: auto;
  margin: auto;
  padding: 0px;
 
}
.residences-header{
    gap:5px;
    display: flex;
    width:100%;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
  
}
.city-homes-two{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  align-self: stretch;
  padding:35px 0px;
  text-decoration: none!important;
  &:hover{
    color: #D88366;
  }

}
.residences-two {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 25px;
    gap: 50px;
    flex: none;
    order: 4;
    align-self: stretch;
    flex-grow: 0;

  }
  .frame4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 5px;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .residences {
     font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.1em;
    color: $terracotta;
  }
  // .whatWeOffer {
  //   font-family: 'Playfair Display';
  //   font-style: normal;
  //   font-weight: 400;
  //   font-size: 34px;
  //   line-height: 45px;
  //   color: $black;
  //   flex: none;
  //   order: 1;
  //   flex-grow: 0;
  // }
  // .offerList {
   
  //   padding: 0 15px;
  //   display: flex;
  //   flex-direction: column;
  //   align-items: flex-start;
  //   align-self: stretch;
  // }

  // .frame39466 {
  //   margin-bottom: 35px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;
  //   align-self: stretch;
  // }
  .num01 {
    width: 25px;
    height: 15.14px;
  }
  .city-homes {
    font-family: "Playfair Display";
    font-size: 24px;
    font-weight: 400;
    line-height: normal;
    color: $black;
    text-decoration: none!important;
    &:hover{
      color:#D88366;
    }
  }
  .city-homes-image1{
    width: 50%;
    max-width: 600px;
    height: auto;
    align-self: center;
    border-radius: 8px;
  }
  .num02 {
    width: 25px;
    height: 13.83px;
  }
  .num03 {
    width: 25px;
    height: 17.81px;
  }
  .num04 {
    width: 25px;
    height: 16.66px;
  }
  // .frame39467 {
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: center;
  //   align-items: center;
  //   padding: 18px 0px;
  //   margin: auto;
  //   gap: 10px;
  //   width: 340px;
  //   background: #D88366;
  //   border-radius: 8px;
  //   flex: none;
  //   order: 2;
  //   align-self: stretch;
  //   flex-grow: 0;
  // }
  .request-info {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .residences-divider {
    border-top: 1px solid $grey-1;
  }
 
.residences-cards{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width:100%;
}
.residences-card{
  background-color: $white;
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.residences-card-img {
  width: 100%;
  max-width: 710px;
  height: auto;
  margin: auto;
}
.residences-card-body {
  padding: 35px 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  align-self: stretch;
}
.residences-card-header {
  width: 100%;
  max-width: 600px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
}
.residences-card-title {
  width: 100%;
  font-family: "Playfair Display";
  font-weight: 400;
  font-size: 24px;
  line-height: normal;
  text-transform:capitalize;
  color: $black;
  margin-bottom: 25px;
}
.floorplans-btn {
  border-radius: 8px;
  padding: 13px 0px;
  width:80%;
  max-width: 250px;
  min-width: 192px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  justify-content: center;
  border: 1px solid $black;
  text-decoration: none !important;
}
.floorplans-btn-text {
  font-family: "Proxima Nova";
  font-size: 14px;
  font-weight: 600;
  color: $black;
  text-transform: uppercase;
}
.residences-card-divider {
  padding: 25px 0px;
  height: 1px;
  border-top: 1px solid $grey-1;
  width: 100%;
  &:last-of-type{
    padding: 0px;
  }
}
  @media screen and (min-width:834px) {
    .residences-divider {
      width:100%;
      border-top: 1px solid $grey-1;
    }
   .residences{
    padding: 100px;
   }
    .residences-body{
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      gap: 50px;
      margin:100px;
    }
    .residences-body-text{
      width:50%;
     
    }
   .residences-sub{
    width: 174px;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 3px;
  }
  .residences-title{
    font-size: 60px;
    gap:15px;
  }
  }

  @media screen and (max-width:1126px) {
    .residences{
      padding: 40px;
     }
  }
  @media screen and (min-width:1800px) {
    .residences-body-text{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width:50%;
      margin: auto 0px;
     
    }
    .city-homes-image1{
      max-width: 1000px;
      height: auto;
      align-self: center;
    }
  }