

.transition-image{
    width:100%;
    height: auto;
    position: absolute;
    filter: brightness(50%);
    top: 0;
    right: 0;
    z-index: 999;
    border-radius: 0px 0px 1000px 1000px;
}
.corso-logo-transition{
    position: absolute;
    width:25%;
    height:auto;
    bottom:0;
    right: -49%;
    z-index: 1000;
    border-radius: 0px 0px 1000px 1000px;
}
.corso-svg{
    height:500px;
    width:500px;
}

@media screen and (min-width:76px){
    .corso-logo-transition{
    width:50%;
        top:200px;
        right: 15%;
       
    }
}