.testcontainer {  
    height: 200vh;
    width: 100vw;
  
  }
  
  .testbox{
    width:100%;
    max-width:300px;
    height:auto;
    min-height: 300px;
    border: 2px solid red;
  }