@import "./keyframes";

.about-us-block {
    padding: 50px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    flex: none;
    order: 5;
    align-self: stretch;
    flex-grow: 0;
  }
  .about-us-header {
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 5px;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .about-us {
    font-family: "Proxima Nova";
    font-size: 16px;
    color: $terracotta;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 5px;
    line-height: 19px;
    letter-spacing: 0.1em;
  }
  .your-experience-div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    gap:20px;
      padding: 0px;
      margin-bottom: 50px;
      width: 85vw;
    }
  .your-experience {
    font-family: "Playfair Display";
    text-align: center;
    font-size: 34px;
    padding: 0px;
    font-weight: 400;
    line-height: normal;
    color: $black;
    margin-bottom:10px;
  }

  .about-us-divider {
    padding: 0px 25px 25px;
    height: 1px;
    border-top: 1px solid $grey-1;
    width: 100%;
    max-width: 600px;
    margin: auto;
    &:last-of-type{
      padding: 0px;
    }
  }
  .about-us-divider1 {
    height: 1px;
    border-top: 1px solid $grey-1;
    width: 91%;
    margin:0px auto;
  }
  .about-us-cards{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width:100%;
}
  .about-us-card{
    background-color: $white;
    border-radius: 8px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .about-us-card-img {
    width: 100%;
    max-width: 800px;
    height: auto;
    margin: auto;
    border-radius: 8px;
  }
  .about-us-card-body {
    padding: 25px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    align-self: stretch;
    
  }
  .about-us-card-header {
    width: 100%;
    max-width: 1000px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
  }
  .about-us-card-title {
    width: 290px;
    font-family: "Playfair Display";
    font-size: 34px;
    font-weight: 400;
    line-height: normal;
    color: $black;
    margin-bottom: 15px;
  }
  .about-us-card-sub {
    margin-bottom: 50px;
    width: 100%;
    max-width: 600px;
    font-family: "Proxima Nova";
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    color: $black;
  }
  .about-us-card-footer {
    border-radius: 8px;
    padding: 13px 31px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $black;
    text-decoration: none;
  }
  .learn-more-link {
    font-family: "Proxima Nova";
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    color: $black;
    text-transform: uppercase;
    margin-right: 5px;
    letter-spacing: 1.25px;
  }
  .frame-divider {
    padding: 3.75px;
    width: 7.5px;
    height: 7.5px;
    position: relative;
  }
  .vector {
    width: 100%;
    height: 100%;
    position: relative;
  }
  .vectorTwo {
    width: 40.6%;
    height: 40.6%;
    position: absolute;
    left: 5.16px;
    top: 3.75px;
  }
  .frame39467Two {
    background-color: $terracotta;
    border-radius: 8px;
    padding: 18px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: stretch;
  }
  .request-info {
    padding: 16px 54px;
    gap: 10px;
    border:none;
    width: 281px;
    height: 56px;
    background: #D88366;
    border-radius: 8px;
  }
  @media screen and (min-width:760px) {
    .about-us{
      text-align: start;
    }
    .about-us-divider{
      padding: 0px;
      max-width: none;
    }
    .about-us-block {
        align-items: flex-start;
        padding: 100px;
        order: 4;
        align-self: normal;
        flex-grow: 0;
      }
    .about-us-header{
        align-items: flex-start;
        margin-bottom: 0px;
        gap: 15px;
        flex: none;
        order: 0;
        flex-grow: 0;
    }
    .your-experience{
        font-weight: 400;
    font-size: 320%;
    line-height: 80px;
    }
    .request-info{
  
        padding: 16px 54px;
        gap: 10px;
        border:none;
        width: 281px;
        height: 56px;
        background: #D88366;
        border-radius: 8px;
         
    }
    .about-us-cards{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px 50px;
        gap: 50px;
        width:100%;

    }
    .about-us-card{
        background-color: $white;
        padding: 0px;
        gap:50px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: normal;
        width:100%;
      }
      .about-us-card-img {
        width: 45%;
        height: auto;
        border-radius: 8px;
      }
      .about-us-card-body {
        align-self: stretch;
        width:100%;
        justify-content: space-between;
        align-items: normal;
        padding: 0px;
        gap: 25px;
      }
      
      .about-us-card-title {
        font-size: 48px;
        font-weight: 400;
        width: 100%;
      }
      .about-us-card-sub {
        font-size: 18px;
        max-width: 100%;
      }
      .about-us-card-footer{
        align-self: flex-end;
      }
  
  }

  @media screen and (min-width:760px) and (max-width:1075px){
    .about-us-cards{
      padding:0px;
    }
    .about-us-block{
      padding: 60px 40px;
    }
    .about-us-divider1{
      width:100%;
    }
  } 