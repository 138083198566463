.blog-block {
    background-color: $grey-2;
    padding: 50px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0px;
    flex: none;
    order: 13;
    align-self: stretch;
    flex-grow: 0;
  }
  .blog-block-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap:5px;
    
    overflow: hidden;
  }
  .blog-posts-div{
    display: flex;
    margin: 25px 0px;
    width: 80%;
    min-width: 300px;
    flex-direction: column;
    justify-content: center;
    gap:50px;
    
  }
  .the-blog {
    font-family: "Proxima Nova";
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    color: $terracotta;
    text-transform: uppercase;
    margin-bottom: 5px;
    letter-spacing: 0.1em;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .recent-posts {
    font-family: "Playfair Display";
    font-size: 34px;
    font-weight: 400;
    line-height: normal;
    color: $black;
    flex: none;
    order: 1;
    flex-grow: 0;
  }
  .blog-post {
    background-color: $white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 500px;
    // align-self: stretch;
    margin:10px auto;
    
  }
  .blog-image {
    width:100%;
    height: auto;
    border-radius: 8px 8px 0px 0px;
  }
  .blog-post-contents {
    padding: 25px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    align-self: stretch;
    gap: 5px;
    flex: none;
    height:60%;
    order: 1;
    background-color: white;
    border-radius: 0px 0px 8px 8px;
  }
  .blog-post-body {
    margin-bottom: 35px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  .blog-date {
    font-family: "Proxima Nova";
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    color: $grey-1;
    margin-bottom: 15px;
  }
  .blog-title {
    font-family: "Playfair Display";
    font-size: 34px;
    font-weight: 400;
    line-height: normal;
    color: $black;
    margin-bottom: 15px;
  }
  .blog-description {
    width: 290px;
    font-family: "Proxima Nova";
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    color: $black;
  }
  .blog-post-footer {
    text-decoration: none !important;
    border-radius: 8px;
    padding: 13px 31px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(65, 88, 102, 1);
  }
  .blog-description2 {
    font-family: "Proxima Nova";
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    color: rgba(65, 88, 102, 1);
    text-transform: uppercase;
    margin-right: 5px;
    letter-spacing: 1.25px;
  }

  .blog-title2 {
    width: 290px;
    font-family: "Playfair Display";
    font-size: 34px;
    font-weight: 400;
    line-height: normal;
    color: $black;
    margin-bottom: 15px;
  }
  .blog-description-three {
    width: 287px;
    font-family: "Proxima Nova";
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    color: $black;
  }
  .blog-link {
    
    background-color: $terracotta;
    border-radius: 8px;
    padding: 18px 0px;
    width:80%;
    max-width: 300px;
    min-width: 190px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self:center;
  }
  .view-the-blog {
    text-decoration: none !important;
    font-family: "Proxima Nova";
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
    color: $white;
    text-transform: uppercase;
    letter-spacing: 2.7px;
  }
 
 
  
  @media screen and (min-width:760px){
    .recent-posts{
      font-size: 60px;
      line-height: normal;
    }
  }
@media screen and (min-width:974px){
  .blog-post{
    min-width: none;
    width:100%;
    max-width: 500px;
    margin:50px 25px;
    
  }
  .blog-post-contents{
    gap: 50px;
    height: 55%;
  }
  .blog-image {
    min-width: none;
    width:100%;
    max-width: 500px;
    
  }
  .blog-posts-div{
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
    min-width: 250px;
    gap:0px;
  }
  .blog-block{
    padding:75px 100px;
    gap: 50px;
  }
  .blog-block-header {
    width:100%;
    align-items:flex-start;
    padding: 0px 50px;
    
   
  }

  .the-blog{
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 0.15em;
  }
  .expanded-blog-header{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }
  .blog-link{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 20px;
    gap: 15px;
    background: #D88366;
    border-radius: 8px;
    text-decoration: none !important;

  }
  
 
}

@media screen and (min-width:974px) and (max-width:1370px){
  .blog-posts-div{
    flex-direction: row;
    flex-wrap: nowrap;
    padding:0px 20px;
  }
  .blog-block{
    padding:25px 20px;
  }
  
.blog-post{
  min-width: 280px;
  width:31.33%;
  max-width:350px;
  margin:25px 10px;
}
.blog-image {
  min-width: 280px;
  max-width:350px;
  min-width: none;
}
.blog-title {
  font-size: 26px;
  
}
}

@media screen and (max-width:500px){
  .blog-block{
    padding:15px 20px;
  }
}